import { connect } from 'react-redux'

import Invoice from './invoice/Invoice.js'

import { fetchInvoiceGet, fetchInvoiceSaveField } from '../../../actions/invoice'

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,		
		bill: state.bill,
		productList: state.product.list,
		shopList: state.shop.list,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		invoiceGet: (id) => dispatch( fetchInvoiceGet( id ) ), 
		onSaveField: (id,name,value) => dispatch( fetchInvoiceSaveField(id,name,value) ),		
	}
}
 
const InvoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoice)


export default InvoiceContainer;