import {countryService} from '../services';
import { alertActions } from './';

export const REQUEST_COUNTRY_LIST = 'REQUEST_COUNTRY_LIST'
function requestCountryList() {
  return {
    type: REQUEST_COUNTRY_LIST,    
  }
}

export const RECEIVE_COUNTRY_LIST = 'RECEIVE_COUNTRY_LIST'
function receiveCountryList( list ) {
  return {
    type: RECEIVE_COUNTRY_LIST,
    list: list,
  }
}

export function fetchCountryList( ) {
 
  return function (dispatch) {
    dispatch(requestCountryList())

    return countryService.loadList( )
      .then(
        list => { 
          dispatch( receiveCountryList(list) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}