import {invoiceService} from '../services';
import { alertActions } from './';

export const REQUEST_INVOICE_GET = 'REQUEST_INVOICE_GET'
function requestInvoiceGet( ) {
  return {
    type: REQUEST_INVOICE_GET,
  }
}

export const RECEIVE_INVOICE_GET = 'RECEIVE_INVOICE_GET'
function receiveInvoiceGet(invoice) {
  return {
    type: RECEIVE_INVOICE_GET,
    invoice: invoice,
  }
}

export function fetchInvoiceGet( user, billId ) {
  return function (dispatch) {
    dispatch(requestInvoiceGet())

    return invoiceService.getByBillId( user, billId )
      .then(
        list => { 
          dispatch( receiveInvoiceGet(list) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_INVOICE_SAVE_FIELD = 'REQUEST_INVOICE_SAVE_FIELD'
function requestInvoiceSaveField( id ) {
  return {
    type: REQUEST_INVOICE_SAVE_FIELD,
    id
  }
}

export const RECEIVE_INVOICE_SAVE_FIELD = 'RECEIVE_INVOICE_SAVE_FIELD'
function receiveInvoiceSaveField(elem) {
  return {
    type: RECEIVE_INVOICE_SAVE_FIELD,
    invoice: elem,
  }
}

export function fetchInvoiceSaveField( user, billId, name, value ) {
  return function (dispatch) {
    dispatch(requestInvoiceSaveField())

    return invoiceService.saveField( user, billId, name, value )
      .then(
        list => { 
          dispatch( receiveInvoiceSaveField(list) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}