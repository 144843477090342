import { connect } from 'react-redux'

import ProductBillForm from '../components/productBill/ProductBillForm'

import { fetchProductBillLoad} from '../actions/productBill'


const mapStateToProps = (state, ownProps) => {
	return {
		...state,
		...ownProps,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		productBillLoad: (user, id) => dispatch( fetchProductBillLoad(user, id) ),
	}
}
 
const ProductBillAction = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductBillForm)


export default ProductBillAction;