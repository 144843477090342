import React from 'react';

import PageGeneral from '../../components/PageGeneral';

import ProductList from '../../containers/product/ProductList.js';

import ProductView from '../../containers/product/ProductView';

const ProductPage = (props) => {	

	const menu = <ProductList />
	const main = <ProductView productId={props.match.params.productId} />	
	    
	return <PageGeneral menu={menu} main={main} />
}

export default ProductPage;  