import React, {Component} from 'react';
import update from 'immutability-helper';

class ProductAddForm extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			product: this.emptyProduct(),			
		}
		this.handleChange = this.handleChange.bind(this);
	}

	emptyProduct(){
		let emptyProduct = { 
			name: "",
			type: "",
		}
		return emptyProduct;
	}

	setEmptyProduct(){
		let newState = update( this.state, {
			product: {$set: this.emptyProduct()}
		});		
		this.setState( newState );
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			product: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	render(){	
		const product =	this.state.product;

		return (
			<div className="row">
		  		<div className="col-7">
		  			<input type="text" className="form-control" placeholder="Product Name"
					    name="name" value={product.name} 
					    onChange={this.handleChange}/>
		  		</div>	
		  		<div className="col-2">
  					<button type="button" className="btn btn-primary"
  						onClick={() => {
  							var elem = this;
  							this.props.onAdd(this.props.auth.user, product)
  								.then(function(){
  									elem.setEmptyProduct()
  								})
  							}
  						}
  						>Add</button>
		  		</div>	  				
		  	</div>
		);
	}
}


export default ProductAddForm;