import React from 'react';
import { connect } from 'react-redux'

import { Link } from 'react-router-dom';

import {getConstant} from '../../functions/functions';
import {productTypeList} from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let ProductList = ( props ) => { 
	if( ! props.productList ) return false
	
	let list = props.productList;
	const productMenu = Object.keys( list )
		.sort((a, b) =>  {
			if( list[a].name <= list[b].name ){
				return 1;
			}else{
				return -1;
			}
		})
		.reverse()
		.map( ( i ) => {	
			const product = list[i];
			var icon ="";
			if( product.type ){
				const productType = getConstant( productTypeList, product.type );
				icon = (productType.icon)?<FontAwesomeIcon icon={productType.icon} style={{'color': 'green'}}/>:"";
			}

			let extraClass = (parseInt(product.num_bill,10) === 0)?" text-danger":"";
			let badge = (parseInt(product.num_bill,10) > 1)?<span className="badge badge-primary">{product.num_bill}</span>:"";

			let linkText = <span>{icon} {product.name} {badge}</span>
			let linkLabel = (parseInt(product.num_det,10) === 0)?<del>{linkText}</del>:<span>{linkText}</span>;
	 		return (
	 			<li className="nav-item" key={product.id} >
					<Link className={"nav-link"+extraClass} to={`/product/${product.id}`} >
						{linkLabel}
					</Link>
		        </li>
	  		);
		});
		
	return (
		<ul className="nav flex-column">{productMenu}</ul>
	)
}

const mapStateToProps = (state,ownProps)  => {  
  return {
    auth: state.authentication,   
    productList: state.product.list
  }
}

ProductList = connect(mapStateToProps)(ProductList)
 
export default ProductList