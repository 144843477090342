import React, {Component} from 'react';

import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';

import { withStyles } from '@material-ui/core/styles';

import BillRegionRenderer from '../../../../components/billRegion/BillRegionRenderer';

import InvoiceZoomable from '../../../../components/invoice/InvoiceZoomable';

import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import LoupeIcon from '@material-ui/icons/Loupe';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import FlareIcon from '@material-ui/icons/Flare';

import {DOMAIN_ASSETS} from '../../../../constants';

//var imageExists = require('image-exists');

const styles = theme => ({
	container: {
		height: "100vh",
		position: "relative",
		overflow: "hidden",
	},
  	imgContainer: {
		overflow: "scroll",
		height: "100vh",
	},
	toolBox: {
		marginTop: theme.spacing(7),
		paddingTop: theme.spacing(1),
		position: "absolute",
		right: theme.spacing(2),
		top: 0,
		display: "flex",
		flexDirection: "column",
		zIndex: 2

	},
	toolBoxButton: {		
		margin: theme.spacing.unit
	}
})


class BillImage extends Component { 
  	constructor(props) {
   		super(props);

   		this.state = {
			toolBoxAction: "zoom",
		}
  	}	

  	handleToolBoxAction = () => {
  		let toolBoxAction = "zoom"
  		if( this.state.toolBoxAction === "zoom"){
  			toolBoxAction = "tag"
  		}
    	this.setState({ toolBoxAction: toolBoxAction });
  	}  	

  	getToolBoxIcon = () => {
  		let toolBoxIcon = <LoupeIcon />
  		if( this.state.toolBoxAction === "tag" ){
  			toolBoxIcon = <FilterCenterFocusIcon />
  		}
  		return toolBoxIcon
  	}

  	renderToolBox(){
		const {classes,invoice,handleInspectClick} = this.props

		return <div className={classes.toolBox} >
			<Fab variant="round" size="small" color="secondary"
				className={classes.toolBoxButton} 
				onClick={this.handleToolBoxAction} >
				{this.getToolBoxIcon()}
			</Fab>
			<Fab variant="round" size="small"  color="secondary"
				className={classes.toolBoxButton} 
				onClick={(id) => handleInspectClick(invoice.bill_id)} >
				<FlareIcon />
			</Fab>			
			
			<Hidden mdUp>
				<Fab variant="round" size="small"  color="secondary"
					className={classes.toolBoxButton} 
					onClick={this.props.handleImageExpanded} >
					<UnfoldMoreIcon />
				</Fab>
			</Hidden>
		</div>
  	}

	//style={{height: this.setImageHeight()}}
  	render(){
		const {classes, invoice, handleReadRegionsClick} = this.props
		if( !invoice.file_path || !invoice.file_name ) return false

		//var image_src = DOMAIN_ASSETS + invoice.file_path + invoice.file_name;

		return <div>
			{this.renderToolBox()}
			<div className={classes.container}>
				<div className={classes.imgContainer}>
	     			{(this.state.toolBoxAction === "tag")?
	     				<BillRegionRenderer 
							regions={ invoice.regions }
							src={ DOMAIN_ASSETS + invoice.file_path + invoice.file_name }
							billId={ invoice.bill_id }
							handleReadRegionsClick={handleReadRegionsClick}
							onClickScan={ (id, src ) => this.props.readBill(id, src)}
						></BillRegionRenderer>
						:
	     				<InvoiceZoomable src={ DOMAIN_ASSETS + invoice.file_path + invoice.file_name } />     			
	     			}
				</div>
			</div>
		</div>
		
	}
}

export default withStyles(styles)(BillImage);