export function getBillTotal( bill, shop ){
	if( !bill || !shop ) return 0

	var money = 0;
/*
    if( shop.currency_code === "KGS"){
        money = parseFloat(bill.total);
    }else{
        money = (bill.total / shop.currency_rate) * 84.03 ;
    }
*/
	if( shop.currency_code === "KGS"){
        money = bill.total / 84.03;
    }else{
        money = (bill.total / shop.currency_rate);
    }

    return money
}

export function isBillFilterType( bill, shop, filter ){
	if( !bill || !shop) return false

	let isFilter = false

	if( shop.type !== "atm" ){
		Object.keys(filter.showShopType).map( (i) =>{
			let st = filter.showShopType[i]
			if( st.key === shop.type && (st.checked === 1 || st.checked === true) ){
				isFilter = true
			}

			return true
		})
	}

	return isFilter
}
export function isBillFilter( bill, shop, filter ){
	var moment = require("moment");
	if( !bill || !shop) return false

	let isFilter = false
	if( moment(bill.date).isBetween(filter.from, filter.to,null,[] ) ){
		isFilter = isBillFilterType( bill, shop, filter)		
	}

	return isFilter
}

export function getCompensate( billList, shopList, filter ){
	var moment = require("moment")
	let compensate = []

	Object.keys(billList).map(i => {
  		const bill = billList[i]
  		const shop = shopList[bill.shop_id]

  		if( isBillFilterType( bill, shop, filter) ){
	  		let som = getBillTotal( bill, shop )
	   	var d = moment(bill.date);         

			if( bill.days_compensate > 0 ){
		   	let d2 =    d.clone();

		     	d2.add(bill.days_compensate, 'days')
		     	compensate.push({
					billId: bill.bill_id,
		        	ini: d,
		        	end: d2,
		        	amount: som / bill.days_compensate,
		     	});
			}
		}
		return true
	})
	
	return compensate
}