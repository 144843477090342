import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import InvoiceSummary from '../../components/invoice/InvoiceSummary'
import { fetchInvoiceGet } from '../../actions/invoice'

class InvoiceSummaryContainer extends Component { 
	
	constructor(props) {
    	super(props)
    	this.state = {
    		billId: "",
    	}
  	}

  	getInvoice( billId ){
		let newState = update( this.state, {
			billId: {$set: billId},
		});
		this.setState( newState );	

		const dispatch = this.props.dispatch;
		dispatch( fetchInvoiceGet( this.props.auth.user, billId ))
  	}

	componentWillMount( ){
		this.getInvoice( this.props.billId )		
	}

	componentWillReceiveProps( newProps ){
		const billId =	newProps.billId

		if( billId !== this.props.billId){
			this.getInvoice( billId  )
		}
	}

	render(){
		if( !this.props.invoice ) return false
		const invoice =	this.props.invoice
	    return (
	    	<InvoiceSummary 
	    		productId={this.props.productId}
	    		onShowInvoice={this.props.onShowInvoice}
	    		invoice={invoice[this.state.billId]} />      		
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
    	countryList: state.country.list,		
    	invoice: state.bill.invoice,		
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
		invoiceGet: (id) => dispatch( fetchInvoiceGet( id ) ), 
	}
}
 
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSummaryContainer);  