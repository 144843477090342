import React , {Component} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';

import {shopTypeList} from '../../constants/constants';

import {getConstant} from '../../functions/functions';
import ProductBillEditAction from '../../containers/ProductBillEditAction';
import {DOMAIN_URL} from '../../constants/constants';


class InvoiceSummary extends Component {    

    render() { 
        if( !this.props.invoice ) return false;
        
        const invoice = this.props.invoice; 

        var icon ="";
        if( invoice.shop_type ){
            const shopType = getConstant( shopTypeList, invoice.shop_type );
            icon = <FontAwesomeIcon icon={shopType.icon} style={{'color': 'green'}}/>;
        }
        const badgePaid = (invoice.paid !== "1" )? <span className="badge badge-danger" style={{marginLeft: "8px"}}>No Paid</span>:""

        return (
            <div className="card" style={{marginTop: "24px",marginBottom: "12px"}}>
                <div className="card-header">
                    <div className="float-left">
                        {icon} {invoice.shop_name}: 
                        <small style={{fontWeight: "bolder",paddingLet: "4px"}}> {invoice.total} c</small>
                        {badgePaid}<br/>
                        <small>{invoice.date_dma} {invoice.time}</small>
                    </div>
                    {(this.props.onShowInvoice)?(
                    <small className="float-right" style={{paddingTop: '3px'}}>
                        <Button className="btn btn-light"
                            onClick={() => this.props.onShowInvoice(DOMAIN_URL + invoice.file_path + invoice.file_name)}>
                            <FontAwesomeIcon icon="search-plus" style={{'color': 'green'}}/>
                        </Button>
                    </small>):""}
                </div>
                {(this.props.productId)?(
                <div className="card-body">
                    {
                        Object.keys(invoice.product_list).map( (i) => {
                            let product = invoice.product_list[i]
                            if( this.props.productId && product.product_id !== this.props.productId ) return false
                            return(
                                <ProductBillEditAction 
                                    key={product.product_bill_id}
                                    product={product} /> 
                            )
                        })
                    }
                </div>):""}                               
            </div>
        );
    }
}

export default InvoiceSummary;