import React from 'react';
import {API_URL} from '../constants/constants';

export const userService = {
    login,
    logout,
    getLastBillDate,
};

function login(username, password) {
    const url = API_URL +'user/login'
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        })
    };

    return fetch(url, requestOptions)
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then( json => {
            if (!json){
                return Promise.reject(json);                
            }else if(!json.success) { 
                let errorList =    (<div>
                    <strong>Error:</strong>
                        <ul>{
                        json.error_list.map( (error,i) => {
                            return (<li key={"errorList"+ i}>{error}</li>)
                        })
                    }</ul>
                    <i>{json.debug}</i>
                </div>)
                return Promise.reject(errorList);
            }

            return json.elem;
        })        
        .then( user => {
            if (user && user.token) {
                console.log("TOKEN!!!", user);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getLastBillDate( userId ) {
    const url = API_URL +'user/get_last_bill_date'
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
        })
    };

    return fetch(url, requestOptions)
        .then(
            response => {
                if(response.ok){
                    return response.json()
                }else{
                    return false
                }
                
            },
            error => console.log('An error occurred.', error)
        )
        .then( json => {
            if (!json){
                return Promise.reject(json);                
            }else if(!json.success) { 
                let errorList =    (<div>
                    <strong>Error:</strong>
                        <ul>{
                        json.error_list.map( (error,i) => {
                            return (<li key={"errorList"+ i}>{error}</li>)
                        })
                    }</ul>
                    <i>{json.debug}</i>
                </div>)
                return Promise.reject(errorList);
            }

            return json.elem;
        })        
        .then( date => {
            return date;
        });
}