import React, {Component} from 'react';

import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import InvoiceEditContainer from '../../../../components/invoice/invoiceEditContainer/InvoiceEditContainer';
import BillRegion from '../../../../containers/bill/BillRegion';
import BillImage from '../billImage/BillImage';


import Slide from '@material-ui/core/Slide';



const styles = theme => ({
	billImageContainer: {
		height: "50vh",
		marginTop: "23px",
		overflow: "scroll",
	}
	
})

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Bill extends Component {	
	constructor (props) {
		super(props)
		
		this.state = {
			imageExpanded: false,
		}
	}	

	handleImageExpanded = () => {
    	this.setState({ imageExpanded: !this.state.imageExpanded });
  	}
	setImageHeight = () => {
  		return ( this.state.imageExpanded )? "100vh":"50vh"
  		//return ( this.state.imageExpanded )? window.innerHeight +"px":"50vh"
  	}
	render() {		
		const {classes,invoice,open,handleClose} = this.props;
		return <Dialog fullScreen open={open}
					onClose={handleClose}
					TransitionComponent={Transition}>
         <AppBar className={classes.appBar}>
            <Toolbar>
              	<IconButton color="inherit" onClick={handleClose} aria-label="Close">
               		<CloseIcon />
              	</IconButton>
              	<Typography variant="h6" color="inherit" className={classes.flex}>
               		{invoice.shop_name}
              	</Typography>
              	<Button color="inherit" onClick={handleClose}>
               		Delete
              	</Button>
            </Toolbar>
        </AppBar>
        <div className={classes.billImageContainer} style={{height: this.setImageHeight()}}>
     		<BillImage invoice={invoice}
     			handleImageExpanded={this.handleImageExpanded}
    			handleInspectClick={this.props.handleInspectClick}
    			handleReadRegionsClick={this.props.handleReadRegionsClick} />
    	</div>
		<DialogContent>
			<InvoiceEditContainer
				billId={invoice.bill_id}
				invoice={invoice} />

			{(invoice.region)?invoice.region.map( (region) => (
				<BillRegion key={region.id} region={region} bill={invoice}></BillRegion>
			)):""}

         </DialogContent>
		</Dialog>
	}
}

export default withStyles(styles)(Bill)