import React, {Component} from 'react';
import update from 'immutability-helper';

import ProductEditContainer from '../../containers/product/ProductEditContainer';
import PatternProductContainer from '../../containers/pattern/PatternProductContainer';

import ProductBillEditAction from '../../containers/ProductBillEditAction';
import ProductDetAdd from '../../containers/ProductDetAdd';

import BudgetModal from '../../components/BudgetModal';


class ProductBillForm extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			modalId: 0,
			modalBody: "",
			patternList: "",
		}
	}

	openModalProduct( id ){
		const modalBody = <ProductEditContainer productId={id} />
		let newState = update( this.state, {
			modalId: {$set: id},
			modalBody: {$set: modalBody},
		});

		this.setState( newState );
	}

	openModalPattern( id, patternList ){
		const modalBody = <PatternProductContainer 
			id={id}
			patternList={patternList}			
		/>
		let newState = update( this.state, {
			modalId: {$set: id},
			modalBody: {$set: modalBody}
		});

		this.setState( newState );
	}

	onCloseModal(){
		let newState = update( this.state, {
			modalId: { $set: "" },
			modalBody: { $set: "" },
		});

		this.setState( newState );
	}

	render(){
		return (
			<div>
				{(this.props.billProductList)?Object.keys(this.props.billProductList).map( (index) => {
					let productBill = this.props.billProductList[index]
					return(
						<ProductBillEditAction 
							key={productBill.product_bill_id}
							product={productBill}
							productList={this.props.productList} 
							onModalProduct={(i) => this.openModalProduct(productBill.product_id) }
							onModalPattern={(i) => this.openModalPattern(
								productBill.product_det_id, productBill.pattern_list ) }
							/>)
					})
				:""}
				<ProductDetAdd 
					productList={this.props.productList}
					billId={this.props.billId} />
				
				<BudgetModal 
					id={ this.state.modalId }
					body={this.state.modalBody}
					onClose={() => this.onCloseModal()} />
			</div>
		)
		
	}
}

export default ProductBillForm;