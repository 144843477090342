import React from 'react';

import Modal from 'react-bootstrap4-modal';


import {isEmpty} from '../functions/functions';

class BudgetModal extends React.Component {

 	render() { 	
		if( isEmpty(this.props.body) ) return false

		return (
			<div>
			<Modal visible={(isEmpty(this.props.id))?false:true} 
				onClickBackdrop={() => this.props.onClose()} >
				<div className="modal-body">
					{this.props.body}
	        	</div>
			</Modal>
			</div>
		);
  }
}

export default BudgetModal;