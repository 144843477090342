import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const TopMenu = () => (
	<ul className="nav nav-pills" style={{marginTop: '12px',marginBottom: '12px'}}>   
        <li className="nav-item" >
            <NavLink className="nav-link" activeClassName='active' to={`/bill`} >
                <FontAwesomeIcon icon="file-alt" />
            </NavLink>      			
        </li>
        <li className="nav-item">
    	   <NavLink className="nav-link" activeClassName='active' to={`/shop`} >
                <FontAwesomeIcon icon="briefcase" />
           </NavLink>
        </li>
        <li className="nav-item">
    	   <NavLink className="nav-link" activeClassName='active' to={`/product`} >
                <FontAwesomeIcon icon="lemon" />   
           </NavLink>
        </li>   
        <li className="nav-item">
            <NavLink className="nav-link" activeClassName='active' to={`/stats`} >
                <FontAwesomeIcon icon="chart-bar" />  
            </NavLink>
        </li>
        <li className="nav-item pull-right">
            <NavLink className="nav-link" activeClassName='active' to={`/logout`} >
                <FontAwesomeIcon icon="sign-out-alt" />  
            </NavLink>
        </li> 
	</ul>
);

export default TopMenu;