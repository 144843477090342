import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

import RegionSelect from 'react-region-select';
import objectAssign from 'object-assign';

import {regionsType} from '../../constants';

import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';

//https://github.com/casavi/react-region-select

const styles = theme => ({	
	container: {
		position: "relative",
		height: "100vh",
	},
	toolBox: {
		marginTop: theme.spacing(7),
		paddingTop: theme.spacing(1),
		position: "absolute",
		left: theme.spacing(2),
		top: 0,
		display: "flex",
		flexDirection: "column",
		zIndex: 2

	},
	toolBoxButton: {		
		margin: theme.spacing(1)
	}
})

class BillRegionRenderer extends Component {	
	constructor (props) {
		super(props);
		this.regionRenderer = this.regionRenderer.bind(this);
		this.onChange = this.onChange.bind(this);

		this.state = {
			billId: this.props.billId,
			regionsType: regionsType,
			regions: this.props.regions,
		};
	}

	componentWillReceiveProps( nextProps ){
		this.setState({			
			billId: nextProps.billId,
			regions: nextProps.regions,
		});
	}

	onChange (regions) {
		this.setState({
			regions: regions
		});
	}
	
	changeRegionData (index, event) {	
		const region = this.state.regions[index];

		region.data.dataType = event.target.value;		

		/***** We Associate the Region with the Select Val *****/
		let regions = [];
		if( index > 0 ){
			regions =	this.state.regions.slice(0, index);
		}
		
		let region_updated = objectAssign({}, region, {
				data: objectAssign({}, region.data, { dataType: event.target.value })} );		
		
		regions.push( region_updated );
		
		if( index < this.state.regions.length -1 ){
			regions =	objectAssign( {}, regions, this.state.regions.slice(index) );
		}

		this.onChange( regions );
	}


	regionRenderer (regionProps) {		
		const optionRegions = this.state.regionsType.map( (reg) => {
			if( !reg.done ){
				return (
       				<option key={reg.key} value={reg.key} >{reg.label}</option>
      			);
			}
			return true;
		});

		if (!regionProps.isChanging) {
			return (
				<div style={{ position: 'absolute', right: 0, bottom: '-1.5em' }}>
					<select onChange={(event) => this.changeRegionData(regionProps.index, event)} 
						value={regionProps.data.dataType}>	
						<option key="0" value="">-</option>					
						{optionRegions}						
					</select>
				</div>
			);
		}
	}

	render() {	
		const { classes, handleReadRegionsClick } = this.props

		const regionStyle = {
			background: 'rgba(255, 0, 0, 0.5)'
		};		

		return	<div className={classes.container}>
			<div className={classes.toolBox} >
				<Fab variant="round" size="small"  color="secondary"
					className={classes.toolBoxButton} 
					onClick={(id,regions) => handleReadRegionsClick( this.state.billId, this.state.regions ) } >
					<SaveIcon />
				</Fab>
			</div>
			<RegionSelect
				maxRegions={5}
				regions={this.state.regions}
    			regionStyle={regionStyle}
				constraint
				onChange={this.onChange}
				regionRenderer={this.regionRenderer}
				style={{ border: '1px solid black' }} >
				<img src={this.props.src} alt="Define Regions of the bill" style={{width: '100%',}}/>							
			</RegionSelect>
		</div>
	}
}

export default withStyles(styles)(BillRegionRenderer);