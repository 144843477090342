import React, {Component} from 'react';
import update from 'immutability-helper';

class PatternAddForm extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			pattern: this.emptyPattern(),			
		}
		this.handleChange = this.handleChange.bind(this);
	}

	emptyPattern(){
		let emptyPattern = { 
			label: "",
			pattern: "",
		}
		return emptyPattern;
	}

	setEmptyPattern(){
		let newState = update( this.state, {
			pattern: {$set: this.emptyPattern()}
		});		
		this.setState( newState );
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			pattern: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	render(){	
		const pattern =	this.state.pattern;

		return (
			<div className="row">
		  		<div className="col-5">
		  			<input type="text" className="form-control" placeholder="Label"
						name="label" value={pattern.label} 
						onChange={this.handleChange} />
		  		</div>
		  		<div className="col-5">
		  			<input type="text" className="form-control" placeholder="Pattern"
						name="pattern" value={pattern.pattern} 
						onChange={this.handleChange} />
		  		</div>	
		  		<div>
		  			<input className="btn btn-primary" type="button" value="Add" 
		  				style={{width: '100%'}}
		  				onClick={() => {
		  					this.props.onAdd(this.props.auth.user, this.props.relId, pattern)
		  						.then(() => {
		  							this.setEmptyPattern()
		  						})
		  					}
		  				} />
		  		</div>	  				
		  	</div>
		);
	}
}

export default PatternAddForm;