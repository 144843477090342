import React from 'react';

import update from 'immutability-helper';


import {productTypeList} from '../../constants/constants';


class ProductEdit extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			product: {},
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentWillMount(){
		this.setState({
			product: this.props.product	
		});	
	}

	componentWillReceiveProps( nextProps ){
		this.setState({
			product: nextProps.product	
		});

	}

	handleBlur( event ){
  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.props.onSaveField( this.props.auth.user, this.props.product.id, name, value )
  	}	

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;		
		
		let newState = update( this.state, {
			product: { [name]: {$set: value} },
		});

		this.setState( newState );

	}

 	render() { 	
 		if( !this.state.product ) return false; 		
 		
		let product =	this.state.product;

		return (
			<div>
				<div className="form-group">
				    <label>Name</label>
					<input type="text" name="name" 
						className="form-control" value={product.name}
						onChange={this.handleChange}
						onBlur={this.handleBlur} />	
				</div>	

				<div className="form-group">
			    	<label>Type</label>
			    	<select className="form-control" name="type" 
			    		value={product.type} 
			    		onChange={this.handleChange}
						onBlur={this.handleBlur} >
			    		<option value="">-</option>
			    		{productTypeList.map( (productType) => (
							<option 
								key={productType.key} 
								value={productType.key}>{productType.label}</option>
						))}
					</select>					    	
			  	</div>
			</div>
		);
  }
}

export default ProductEdit;