import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import ProductEditForm from '../../components/product/ProductEditForm'
//import PatternProductContainer from '../../containers/pattern/PatternProductContainer'
import ProductDelete from '../../containers/product/ProductDelete';
import { fetchProductGet, fetchProductSaveField } from '../../actions'


class ProductEditContainer extends Component { 
	
	constructor(props) {
    	super(props)
    	this.state = {
    		productId: "",
    	}
  	}

  	getProduct( productId ){
		let newState = update( this.state, {
			productId: {$set: productId},
		});
		this.setState( newState );	

		const dispatch = this.props.dispatch;
		dispatch( fetchProductGet( this.props.auth.user, productId ))
  	}

	componentWillMount( ){
		if( !this.props.productId ) return false
		this.getProduct( this.props.productId )		
	}

	componentWillReceiveProps( newProps ){
		if( !newProps.productId ) return false
		const productId =	newProps.productId

		if( productId !== this.props.productId){
			this.getProduct( productId  )
		}
	}

	render(){
		if( !this.props.product ) return false
		if( !this.props.product[this.state.productId] ) return false
		const product =	this.props.product[this.state.productId]
	    return (
  			<div className="card">
				<div className="card-header">
					{product.name} 
					<div className="float-right">
						<ProductDelete id={product.id} />
					</div>
				</div>
				<div className="card-body">			
					<ProductEditForm 
						product={product} 
						auth={this.props.auth} 
						onSaveField={(user, id, name, value) => this.props.onSaveField(user, id, name, value)}
						/>
				</div>
			</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
    	product: state.product.product,		
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,		
		onSaveField: (user, id, name, value) => dispatch( fetchProductSaveField(user, id, name, value) ),
	}
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ProductEditContainer);  