import {fetchApi} from '../helpers';

export const invoiceService = {
    getByBillId,
    saveField,
};

function getByBillId( user, billId ) {
    return fetchApi('invoice/get_by_bill_id', {
    	user: user,
    	bill_id: billId,
    })   
}

function saveField( user, id, name, value) {
    return fetchApi('invoice/save_field/', {user: user, id: id, name: name, value: value })   
}