import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import ShopAdd from './shopAdd/ShopAdd'

import { shopActions } from '../../../actions'
//import { fetchShopAdd } from '../../actions'

class ShopAddContainer extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			shop: this.emptyShop(),			
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	emptyShop(){
		let emptyShop = { 
			name: "",
			type: "",
			country_id: "",
		}
		return emptyShop;
	}

	setEmptyShop(){
		let newState = update( this.state, {
			shop: {$set: this.emptyShop()}
		});		
		this.setState( newState );
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			shop: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit(event) {
        const {dispatch,authentication} = this.props

		dispatch( shopActions.add( authentication.user, this.state.shop) )
        this.setEmptyShop()
	}

	render(){	
		if( !this.props.countryList
			|| !this.props.cityList ) return false;

		return (
			<ShopAdd 
				shop={this.state.shop}
				countryList={this.props.countryList} 
				cityList={this.props.cityList}
				handleChange={this.handleChange}
				handleSubmit={this.handleSubmit} />
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		authentication: state.authentication,
		countryList: state.country.list,
		cityList: state.city.list,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopAddContainer);
