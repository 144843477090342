import {
  SHOP_LOAD_REQUEST,
  SHOP_LOAD_SUCCESS,
  SHOP_LOAD_FAILURE,
  SHOP_ADD_REQUEST,
  SHOP_ADD_SUCCESS,
  SHOP_ADD_FAILURE,  
  SHOP_DELETE_REQUEST,
  SHOP_DELETE_SUCCESS,
  SHOP_DELETE_FAILURE,  


  REQUEST_SHOP_GET,
  RECEIVE_SHOP_GET,  
  REQUEST_SHOP_SAVE_FIELD,
  RECEIVE_SHOP_SAVE_FIELD,   
} from '../actions/shop'

import {
  REQUEST_PATTERN_SHOP_ADD,
  RECEIVE_PATTERN_SHOP_ADD,   
  REQUEST_PATTERN_SHOP_SAVE_FIELD,
  RECEIVE_PATTERN_SHOP_SAVE_FIELD, 
  REQUEST_PATTERN_SHOP_DELETE,
  RECEIVE_PATTERN_SHOP_DELETE,
} from '../actions/pattern'

import update from 'immutability-helper';

const shop = (state = [], action) => {
  let newState = "";

  switch (action.type) {
    case SHOP_LOAD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SHOP_LOAD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.rows,
      })
    case SHOP_LOAD_FAILURE:
      return {}  

    case SHOP_ADD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SHOP_ADD_SUCCESS:
      newState = update( state, {
          isFetching: {$set: false},
          list: {
            [action.rows.id]: {$set: action.rows},
          }
        });      
      return newState
    case SHOP_ADD_FAILURE:
      return {} 

    case REQUEST_SHOP_GET:
      return Object.assign({}, state, {
        isFetching: true,

      })
    case RECEIVE_SHOP_GET:
      if( state.invoice){
        newState = update( state, {
          isFetching: {$set: false},
          shop:{ [action.shop.id]: {$set: action.shop} },
        })
      } else{
        newState = update( state, {
          isFetching: {$set: false},
          shop: {$set: { [action.shop.id]: action.shop } },
        });
      }
      return newState
   

    case REQUEST_PATTERN_SHOP_ADD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PATTERN_SHOP_ADD:    
      newState = update( state, {
          isFetching: {$set: false},
          shop: {
            [action.pattern.shop_id] :{
              pattern_list:{
                [action.pattern.id]: {$set: action.pattern},
              }
            }
          }
        });
      return newState  
    case REQUEST_SHOP_SAVE_FIELD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_SHOP_SAVE_FIELD:    
    
      newState = update( state, {
          isFetching: {$set: false},
          list: {
            [action.shop.id]: {$set: action.shop},
          },
          shop: {$set: { [action.shop.id]: action.shop } },
        });
      return newState
    case REQUEST_PATTERN_SHOP_SAVE_FIELD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PATTERN_SHOP_SAVE_FIELD:    
      newState = update( state, {
          isFetching: {$set: false},
          shop: {
            [action.pattern.shop_id] :{
              pattern_list:{
                [action.pattern.id]: {$set: action.pattern},
              }
            }
          }
        });
      return newState    
    
    case SHOP_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SHOP_DELETE_SUCCESS:    
      newState = update( state, {
          isFetching: {$set: false},
          list: {$unset: [action.rows]},
          shop: {$set: {}},
        });
      return newState
    case SHOP_DELETE_FAILURE:
      return {} 


    case REQUEST_PATTERN_SHOP_DELETE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PATTERN_SHOP_DELETE:    
      newState = update( state, {
          isFetching: {$set: false},
          shop: {
            [action.pattern.shop_id] :{
              pattern_list: {$unset: [action.pattern.id]}
            }
          },
        });
      return newState  
    default:
      return state
  }
}

export default shop