import React, {Component} from 'react';
import update from 'immutability-helper';

import ZoomableImage from 'react-zoomable-image'

var url = require('url');
var http = require('http');
var sizeOf = require('image-size');


class InvoiceZoomable extends Component {    

    constructor(props) {
        super(props);

        this.state = {
            width: 100,
            height: 100,
            zoomWidth: 0,
            zoomHeight: 0
        };
        this.refContainer = React.createRef();
        this.setZoomDimension = this.setZoomDimension.bind(this);

    }

    setZoomDimension( imgUrl ){
        var options = url.parse(imgUrl);
        let dimensions = ""
        let that = this
        http.get(options, function (response) {
            var chunks = [];
            response.on('data', function (chunk) {
                    chunks.push(chunk);
            }).on('end', function() {
                var buffer = Buffer.concat(chunks);
                dimensions = sizeOf(buffer);                
                let newState = update( that.state, {
                    zoomWidth: {$set: dimensions.width},
                    zoomHeight: {$set: dimensions.height},
                });
                that.setState( newState )
            });
        });
    }

    componentWillMount( ){
        this.componentWillReceiveProps(this.props)
    }
    
    componentWillReceiveProps(nextProps){
        if( !nextProps.src ) return false
        this.setZoomDimension( nextProps.src )
    }


    componentDidUpdate() {
        if( this.refContainer
            && this.refContainer.current
            && this.state.width !==  this.refContainer.current.clientWidth ){ 
            const newWidth =   this.refContainer.current.clientWidth
            let newHeight = this.state.zoomHeight * (newWidth/this.state.zoomWidth)
            let newState =update( this.state, {
                width: {$set: newWidth},
                height: {$set: newHeight},
            });
            this.setState( newState )
        }
    }

    componentWillUnmount(){

    }
    componentDidCatch(error, info){
        console.log(error,info,"JODER")
    }
    render() { 
        if( !this.props.src
        || !this.state.width
        || !this.state.height
        || !this.state.zoomWidth
        || !this.state.zoomHeight 
        || !this.refContainer) return false
  
        return (
            <div ref={this.refContainer}  style={{width: "100%",height: "100%",backgroundColor: "cyan"}}>
              <ZoomableImage
                    displayMap={false}
                    baseImage={{
                        alt: 'Image zoomable',
                        src: this.props.src,
                        width: this.state.width,
                        height: this.state.height,
                    }}
                    largeImage={{
                        alt: 'Image zoom',
                        src: this.props.src,
                        width: this.state.zoomWidth,
                        height: this.state.zoomHeight,
                    }}
                />  
            </div>
        );
    }
}

export default InvoiceZoomable;