import {API_URL} from '../constants/constants';

import {productService} from '../services';
import { alertActions } from './';

export const REQUEST_PRODUCT_LIST = 'REQUEST_PRODUCT_LIST'
function requestProductList() {
  return {
    type: REQUEST_PRODUCT_LIST,    
  }
}

export const RECEIVE_PRODUCT_LIST = 'RECEIVE_PRODUCT_LIST'
function receiveProductList( elem ) {
  return {
    type: RECEIVE_PRODUCT_LIST,
    list: elem,
  }
}

export function fetchProductList(user) {
 
  return function (dispatch) {
    dispatch(requestProductList())

    return productService.load( user )
      .then(
        elem => { 
          dispatch( receiveProductList(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PRODUCT_GET = 'REQUEST_PRODUCT_GET'
function requestProductGet( ) {
  return {
    type: REQUEST_PRODUCT_GET,
  }
}

export const RECEIVE_PRODUCT_GET = 'RECEIVE_PRODUCT_GET'
function receiveProductGet(product) {
  return {
    type: RECEIVE_PRODUCT_GET,
    product: product,
  }
}

export function fetchProductGet( user, productId ) {
  return function (dispatch) {
    dispatch(requestProductGet())

    return productService.get( user, productId )
      .then(
        elem => { 
          dispatch( receiveProductGet(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PRODUCT_ADD = 'REQUEST_PRODUCT_ADD'
function requestProductAdd() {
  return {
    type: REQUEST_PRODUCT_ADD,    
  }
}

export const RECEIVE_PRODUCT_ADD = 'RECEIVE_PRODUCT_ADD'
function receiveProductAdd( product ) {
  return {
    type: RECEIVE_PRODUCT_ADD,
    product: product,
  }
}

export function fetchProductAdd(user, product) {
 
  return function (dispatch) {
    dispatch(requestProductAdd())

    return productService.add( user, product )
      .then(
        elem => { 
          dispatch( receiveProductAdd(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PRODUCT_SAVE_FIELD = 'REQUEST_PRODUCT_SAVE_FIELD'
function requestProductSaveField( ) {
  return {
    type: REQUEST_PRODUCT_SAVE_FIELD,
  }
}

export const RECEIVE_PRODUCT_SAVE_FIELD = 'RECEIVE_PRODUCT_SAVE_FIELD'
function receiveProductSaveField(elem) {
  return {
    type: RECEIVE_PRODUCT_SAVE_FIELD,
    product: elem,
  }
}

export function fetchProductSaveField( user, id, name, value ) {
  return function (dispatch) {
    dispatch(requestProductSaveField())

    return productService.saveField( user, id, name, value )
      .then(
        elem => { 
          dispatch( receiveProductSaveField(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PRODUCT_DELETE = 'REQUEST_PRODUCT_DELETE'
function requestProductDelete() {
  return {
    type: REQUEST_PRODUCT_DELETE,    
  }
}

export const RECEIVE_PRODUCT_DELETE = 'RECEIVE_PRODUCT_DELETE'
function receiveProductDelete( deletedId ) {
  return {
    type: RECEIVE_PRODUCT_DELETE,
    id: deletedId,
  }
}

export function fetchProductDelete( user, id ) {
 
  return function (dispatch) {
    dispatch(requestProductDelete())

    return productService._delete( user, id )
      .then(
        elem => { 
          dispatch( receiveProductDelete(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PRODUCT_BILL_LOAD = 'REQUEST_PRODUCT_BILL_LOAD'
function requestProductBillLoad( productId ) {
  return {
    type: REQUEST_PRODUCT_BILL_LOAD,
    productId
  }
}

export const RECEIVE_PRODUCT_BILL_LOAD = 'RECEIVE_PRODUCT_BILL_LOAD'
function receiveProductBillLoad( json ) {
  return {
    type: RECEIVE_PRODUCT_BILL_LOAD,
    list: json.elem_list,
  }
}

export function fetchProductBillLoad( productId ) {
 
  return function (dispatch) {
    dispatch(requestProductBillLoad(productId))

    return fetch( API_URL +'product/bill_load_by_product_id/',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: productId,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then(json =>
        dispatch( receiveProductBillLoad(json) )
      )
  }
}

export const REQUEST_PRODUCT_BILL_GET_BY_PRODUCT_BILL = 'REQUEST_PRODUCT_BILL_GET_BY_PRODUCT_BILL'
function requestProductBillLoadGetByProductBill( ) {
  return {
    type: REQUEST_PRODUCT_BILL_GET_BY_PRODUCT_BILL
  }
}

export const RECEIVE_PRODUCT_BILL_GET_BY_PRODUCT_BILL = 'RECEIVE_PRODUCT_BILL_GET_BY_PRODUCT_BILL'
function receiveProductBillLoadByProductBill( json ) {
  return {
    type: RECEIVE_PRODUCT_BILL_GET_BY_PRODUCT_BILL,
    product: json.elem,
  }
}

export function fetchProductBillGetByProductBill( productId, billId ) {
  return function (dispatch) {
    dispatch(requestProductBillLoadGetByProductBill(productId))

    return fetch( API_URL +'product/bill_get_by_product_id_bill_id/',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: productId,
          bill_id: billId
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then(json =>
        dispatch( receiveProductBillLoadByProductBill(json) )
      )
  }
}

export const REQUEST_PRODUCT_SAVE = 'REQUEST_PRODUCT_SAVE' 
function requestProductSave( id ) {
  return {
    type: REQUEST_PRODUCT_SAVE,
    id
  }
}

export const RECEIVE_PRODUCT_SAVE = 'RECEIVE_PRODUCT_SAVE'
function receiveProductSave(json) {
  return {
    type: RECEIVE_PRODUCT_SAVE,
    bill: json.elem,
  }
}

export function fetchProductSave( product ) {
  return function (dispatch) {
    dispatch(requestProductSave())

    return fetch( API_URL +'product/save',
        {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoice: product,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then (json =>
        dispatch(receiveProductSave( json ))
      )
  }
}

export const REQUEST_PRODUCT_DET_SAVE_FIELD = 'REQUEST_PRODUCT_DET_SAVE_FIELD'
 
function requestProductDetSaveField( id ) {
  return {
    type: REQUEST_PRODUCT_DET_SAVE_FIELD,
    id
  }
}

export const RECEIVE_PRODUCT_DET_SAVE_FIELD = 'RECEIVE_PRODUCT_DET_SAVE_FIELD'
 
function receiveProductDetSaveField(json) {
  return {
    type: RECEIVE_PRODUCT_DET_SAVE_FIELD,
    bill: json.elem,
  }
}

export function fetchProductDetSaveField( id, name, value ) {
  return function (dispatch) {
    dispatch(requestProductDetSaveField())

    return fetch( API_URL +'product/det_save_field',
        {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          name: name,
          value: value,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then (json =>
        dispatch(receiveProductDetSaveField( json ))
      )
  }
}