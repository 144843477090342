import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';
import ShopEdit from './shopEdit/ShopEdit';
import { fetchShopSaveField,cityActions } from '../../../actions'

class ShopEditContainer extends Component {
	constructor (props) {
		super(props);
		
		this.state = {
			shop: "",
			cityIsLoading: false,
			cityName: "",
			inputValue: "",
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);		
	}

	componentWillMount( ){
		this.componentWillReceiveProps( this.props )
	}

	componentWillReceiveProps( newProps ){
		if( !newProps.shopList || !newProps.cityList || !newProps.shopId) return false
		let cityName = "";
		let shopId = newProps.shopId
		let shop = newProps.shopList[shopId]

		if( shop.city_id ){
			let city = newProps.cityList.find( c => {
				if(c) return shop.city_id === c.id 
				return false
			})
			if( city ) cityName = city.name
		}
		
		this.setState({
			shop: shop,
			cityName: cityName
		});
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			shop: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleBlur( event ){
  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		this.props.onSaveField( this.props.auth.user, this.state.shop.id, name, value )
  	}

	handleInputChange = (newValue: string) => {
    	const inputValue = newValue.replace(/\W/g, '');
    	this.setState({ inputValue });
    	return inputValue;
  	}

	handleCreate = (newValue: any) => {
		const { shop } = this.state;
		this.setState({ cityIsLoading: true });

  		const city = {
  			country_id: shop.country_id,
  			name: newValue.value
  		}
  		
  		this.props.onCityAdd( this.props.auth.user, city, shop.id);
  	
  		this.setState({
    		cityIsLoading: false,
  		});
  	}

	handleCreateableChange = (newValue: any, actionMeta: any) => {
		const { shop } = this.state;

		if( newValue.__isNew__){
  			this.setState({ cityIsLoading: true });

      		const city = {
      			country_id: shop.country_id,
      			name: newValue.value
      		}
      		
      		this.props.onCityAdd( this.props.auth.user, city, shop.id);
      	
      		this.setState({
        		cityIsLoading: false,
      		});
  		}else{	    	
	    	let newState = update( this.state, {
				shop: { city_id: {$set: newValue.value} },
			});

			this.setState( newState );
			this.props.onSaveField( this.props.auth.user, shop.id, "city_id", newValue.value )
		}
  	}

	render() {		
		if( !this.state.shop ) return false;
		if( !this.props.countryList ) return false;
		if( !this.props.cityList ) return false;
		if( !this.props.auth ) return false;
	
		return <ShopEdit
			shop={this.state.shop} 
			countryList={this.props.countryList} 
			cityList={this.props.cityList}
			cityIsLoading={this.state.cityIsLoading}
			cityName={this.state.cityName}
			handleBlur={this.handleBlur} 
			handleChange={this.handleChange} 
			handleCreateableChange={this.handleCreateableChange}
			/>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
		countryList: state.country.list,
		cityList: state.city.list,
		shopList: state.shop.list,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
		onSaveField: (user, id, name, value) => dispatch( fetchShopSaveField(user,id,name,value) ),	
		onCityAdd: (user, city, shop_id) => dispatch( cityActions.add(user, city, shop_id) ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopEditContainer);  