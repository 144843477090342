import React, {Component} from 'react';
import { connect } from 'react-redux'

import Loading from '../../../components/Loading';
import BillDay from './billMenu/BillDay';


class BillMenuContainer extends Component {

	render(){	
		const {loading,billList,shopList,cityList} = this.props
		if( loading ){
			return <Loading loading={this.props.loading} size="3x" />
		}
		if( !billList
			|| !shopList
			|| !cityList ) return false
		
		var cal = [];
		Object.keys(billList).map( (id) => {
			let bill = billList[id]			
			let shop = ( bill.shop_id )?shopList[bill.shop_id]:{}
			let city = {}
			if( shop && shop.city_id ){
				city = ( shop.city_id )?cityList.find( c => {
					if( c ) return c.id === shop.city_id
					return false
				}):{}
			}
			
			if( !cal[bill.date] ){
				cal[bill.date] = {}
				cal[bill.date].total =	0;
				cal[bill.date].list =	[];
				cal[bill.date].cityList = new Set();				
			}

			if( shop && shop.type !== "atm"){
				if( shop.currency_code === "EUR"){
					cal[bill.date].total +=	parseFloat( bill.total );
				}else{
					const currency = (bill.total / shop.currency_rate);
					cal[bill.date].total += Number(currency.toFixed(2));
				}
			}
			cal[bill.date].cityList.add( city )
			cal[bill.date].list[bill.bill_id] =	{
				bill: bill,
				shop: shop,
			}

			return true;
		});

		var panel_date = Object.keys(cal)
			.sort((a, b) =>  {
				let aTime = new Date(a).getTime();
				let bTime = new Date(b).getTime();
				if( aTime <= bTime ){
					return 1;
				}else{
					return -1;
				}
			})
			.map(( date, index) => {
     		return (
     			<BillDay key={date} date={date} 
     				list={cal[date].list} total={cal[date].total} cityList={Array.from(cal[date].cityList)} />
      		);
    	});		

		return <div>
			<ul className="nav flex-column">
				{panel_date}
			</ul>			
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication, 
		billList: state.bill.list,
		shopList: state.shop.list,
		cityList: state.city.list,
	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillMenuContainer);