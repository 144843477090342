import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Loading = (props) => (
    <div>
        {(props.loading)?(
            <div>
                <FontAwesomeIcon icon="cog" style={{opacity: 0.3,marginLeft: "12px"}} spin/>        
            </div>
        ):""}
    </div>
);

export default Loading;