import { connect } from 'react-redux'

import ProductBillEditForm from '../components/productBill/ProductBillEditForm'

import { fetchProductBillSaveField,fetchProductBillDelete } from '../actions/productBill'

const mapStateToProps = (state, ownProps) => {
	const { authentication } = state;

	return {
		authentication: authentication,
		productList: state.product.list
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSaveField: (user, id,name,value) => dispatch( fetchProductBillSaveField(user, id,name,value) ),
		onDelete: (user, id) => dispatch( fetchProductBillDelete( user, id) )
	}
}
 
const ProductBillEditAction = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductBillEditForm)


export default ProductBillEditAction;