import React from 'react'

import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import { PrivateRoute } from '../components/PrivateRoute';


import ShopPage from '../components/pages/ShopPage';

import ProductPage from '../components/product/ProductPage.js';
import BillPage from '../components/pages/BillPage';

import StatsContainer from '../components/statsContainer/StatsContainer';
import ChartContainer from '../components/chartContainer/ChartContainer';

import LoginContainer from '../components/loginContainer/LoginContainer';

import { userService } from '../services';


import { fetchCountryList, cityActions, fetchBillListByYear, shopActions, fetchProductList } from '../actions'

import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../themes/main';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoneyBillAlt, faBirthdayCake, faBeer, faCoffee, faTshirt, 
	faTicketAlt, faPills, faGift, faHSquare, faHome
	,faBinoculars, faUtensils, faShoppingBag, faBicycle, faShoppingCart,
	faTrain, faPlane, faSearchPlus, faTrashAlt, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faMoneyBillAlt, faBirthdayCake, faBeer, faCoffee, faTshirt, 
	faTicketAlt, faPills, faGift, faHSquare, faHome
	,faBinoculars ,faUtensils, faShoppingBag, faBicycle, faShoppingCart
	,faTrain, faPlane, faSearchPlus, faTrashAlt, faEdit, faPlus)

var moment = require("moment");


class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lastBillUpdate : moment("2018-01-01"),
			year: moment().format("YYYY")
		}
	}
	
	loadBillList = () => {
		const {year} = this.state
		const user = this.props.auth.user

		this.props.dispatch( fetchBillListByYear(user,year) )
		this.setState({
			lastBillUpdate: moment()
		})

	}
	reloadBillList = () => {
  		const user = this.props.auth.user
		userService.getLastBillDate( user.id ).then( date => {
			let lastBillUpdate = this.state.lastBillUpdate.clone()
			if( lastBillUpdate.isSameOrBefore(date) ){
				this.loadBillList()
			}
		})

		setTimeout(this.reloadBillList, 1000 * 20);
	}

	componentWillMount(){
  		const user = this.props.auth.user
        if(user && user.token) {
			this.props.dispatch( shopActions.load(user) )
			this.props.dispatch( fetchProductList(user) )
			this.props.dispatch( fetchCountryList(user) )
			this.props.dispatch( cityActions.load(user) )
			this.reloadBillList()
		}
  	}

  	componentWillReceiveProps(){
 		console.log("App componentWillReceiveProps")		
  	}



  	handleYearChange =  (event: React.ChangeEvent<{ value: unknown }>) => {
  		this.setState({
  			year: event.target.value
  		},() => {
    		this.loadBillList();
		});
  	}

 	render() {
 		const {year} = this.state

		return <MuiThemeProvider theme={theme}>
			<Switch>
			    <Route path="/login" component={LoginContainer} />
			    <Route path="/logout" component={LoginContainer} />

			    <PrivateRoute exact path="/" component={BillPage}
			    	year={year}
			    	handleYearChange={this.handleYearChange} />
			    <PrivateRoute exact path="/bill/" component={BillPage} 
			    	year={year}
			    	handleYearChange={this.handleYearChange} />
			    <PrivateRoute path='/bill/:billId' component={BillPage} 
			    	year={year}
			    	handleYearChange={this.handleYearChange} />

			    <PrivateRoute exact path='/shop' component={ShopPage} />
				<PrivateRoute path='/shop/:shopId' component={ShopPage} />
			
				<PrivateRoute exact path='/product' component={ProductPage} />
				<PrivateRoute path='/product/:productId' component={ProductPage} />
				
				<PrivateRoute exact path='/stats' component={StatsContainer} />	
				
				<PrivateRoute exact path='/chart' component={ChartContainer} />					
			</Switch>
		</MuiThemeProvider>;
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication, 
	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));