import React from 'react'
import { connect } from 'react-redux'

import { fetchProductDelete } from '../../actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


 
let ProductDelete = ( props ) => { 
  return (
      <button className="btn btn-outline-danger btn-sm" type="button" value="Delete" style={{marginLeft: '12px'}}
              onClick={() => { if (window.confirm('Are you sure you wish to delete this product?')) props.onDelete(props.auth.user,props.id) } } >
              <FontAwesomeIcon icon="trash-alt" />
      </button>             
  )
}

const mapStateToProps = (state,ownProps)  => {  
  return {
    auth: state.authentication,     
  }
}
const mapDispatchToProps = (dispatch) => {
	return {
		onDelete: (user, id) => dispatch( fetchProductDelete(user, id)  )
	}
}

ProductDelete = connect(mapStateToProps,mapDispatchToProps)(ProductDelete)
 
export default ProductDelete