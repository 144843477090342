import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';


import BillAdd from './billAdd/BillAdd'

import { billActions } from '../../../actions'

var moment = require("moment");

class BillAddContainer extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			bill: this.emptyBill(),			
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	emptyBill(){
		let emptyBill = { 
			date: moment(),
			shop_id: "",
			total: "",
		}
		return emptyBill;
	}

	setEmptyBill(){
		let newState = update( this.state, {
			bill: {$set: this.emptyBill()}
		});		
		this.setState( newState );
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			bill: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit(event) {
        const {dispatch,authentication} = this.props

		dispatch( billActions.add( authentication.user, this.state.bill) )
        this.setEmptyBill()
	}

	handleSelectChange = (selectedOption) => {			
		if( selectedOption ){
			let newState = update( this.state, {
				bill: { shop_id: {$set: selectedOption.value} },
			});
			this.setState( newState );
		}
  	}

	render(){	
		if( !this.props.shopList ) return false;

		return (
			<BillAdd 
				bill={this.state.bill}
				shopList={this.props.shopList} 
				handleSelectChange={this.handleSelectChange}
				handleChange={this.handleChange}
				handleSubmit={this.handleSubmit} 
				/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		authentication: state.authentication,
		shopList: state.shop.list,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BillAddContainer);
