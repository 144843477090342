import { connect } from 'react-redux'

import PatternAddForm from '../../components/pattern/PatternAddForm'

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
	};
}
 
const PatternAdd = connect(
  mapStateToProps,
)(PatternAddForm)

export default PatternAdd;