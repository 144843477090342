import {API_URL} from '../constants/constants';

export const REQUEST_PRODUCT_DET_ADD = 'REQUEST_PRODUCT_DET_ADD'

function requestProductDetAdd() {
  return {
    type: REQUEST_PRODUCT_DET_ADD,    
  }
}

export const RECEIVE_PRODUCT_DET_ADD = 'RECEIVE_PRODUCT_DET_ADD'
 
function receiveProductDetAdd( json ) {
  return {
    type: RECEIVE_PRODUCT_DET_ADD,
    productDet: json.elem,
  }
}

export function fetchProductDetAdd(product,billId) {
 
  return function (dispatch) {
    dispatch(requestProductDetAdd())

    return fetch( API_URL +'product/det_add', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product: product,
          bill_id: billId,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then(json =>
        dispatch( receiveProductDetAdd(json) )
      )
  }
}
