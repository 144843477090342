import React, {Component} from 'react';
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {  NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/Comment';
import PhotoIcon from '@material-ui/icons/Photo';
import Avatar from '@material-ui/core/Avatar';


import {shopTypeList} from '../../../../constants';
import {getConstant} from '../../../../functions';

const styles = theme => ({
	price:{
		textAlign: "right",
		minWidth: "75px",
	},
	shopIcon: {
		//width: "25px",
		color: "#FFF"
	}
})

class BillLine extends Component {

	render(){	
		const {bill, shop, classes} =	this.props;
		if( !bill ) return false

		var label = bill.file_name;
		var currency_sufix = ""
		var total = "";
		if( shop && shop.name ){
			label =	shop.name;
			currency_sufix = shop.currency_sufix
		}
		if( bill.total > 0){
			total =	<small>{bill.total} {currency_sufix}</small>;
		}	

		var icon = "";
		if( shop && shop.type ){
			const shopType = getConstant( shopTypeList, shop.type );
			icon = <FontAwesomeIcon icon={shopType.icon} />;
		}

		var customIconClass = [];
		var customRowClass = [];

		if( shop && shop.type === "atm"){
			customIconClass.push("shop-atm");
			customRowClass.push("shop-atm");			
		}else{
			//customIconClass.push("text-success");
		}

		var secondary = (bill.paid !== "1" )?<span className="badge badge-danger badge-no-paid" >No Paid</span>:""

		var notes = (bill.notes)?<Tooltip title={bill.notes} style={{marginLeft: "4px"}}><CommentIcon /></Tooltip>:""
		var image_exists = ""
		if( bill.file_path && bill.file_name ){
			image_exists = <PhotoIcon />
		}

		return <ListItem key={bill.bill_id} component={NavLink} to={`/bill/${bill.bill_id}`}  >	
				<ListItemAvatar>
                    <Avatar>
                      	<span className={ "shopIcon " + customIconClass.join(" ")}>{icon}</span>
                    </Avatar>
                  </ListItemAvatar>
				<ListItemIcon>					
					{notes}{image_exists}
				</ListItemIcon>	
				<ListItemText primary={label} secondary={secondary} />
				<ListItemText secondary={total} className={classes.price} />
			</ListItem>
	}
}
/*
	{(bill.paid !== "1" )? <span className="badge badge-danger badge-no-paid" >No Paid</span>:""}
*/

BillLine.propTypes = {
  bill: PropTypes.object.isRequired,
}

export default withStyles(styles)(BillLine)