import React from 'react';
import PropTypes from 'prop-types'

import ProductBillAction from '../../containers/ProductBillAction';

function ProductForm( props ){
	return(
		<div>			
			<ProductBillAction
	  			billProductList={props.invoice.product_list}
	  			productList={props.productList} />
	  	</div>
	);
}

ProductForm.propTypes = {
  invoice: PropTypes.object.isRequired,
  productList: PropTypes.object.isRequired,
}

export default ProductForm;