import {fetchApi} from '../helpers';

export const shopService = {
    loadList,    
    get,
    add,
    saveField,
    _delete,
    patternAdd,
    patternSaveField,
    patternDelete,    
};

function loadList( user ) {
    return fetchApi('shop/load', {user: user})   
}

function get( user, id ) {
    return fetchApi('shop/get', {
    	user: user,
    	id: id,
    })   
}

function add( user, shop ) {
    return fetchApi('shop/add', {
    	user: user,
    	shop: shop,
    })   
}

function saveField( user, id, name, value) {
    return fetchApi('shop/save_field/', {user: user, id: id, name: name, value: value })   
}

function _delete( user, id) {
    return fetchApi('shop/delete/', { user: user, id: id })   
}

function patternAdd( user, relId, pattern) {
    pattern['shop_id'] =    relId
    return fetchApi('shop/pattern_add/', {user: user, pattern: pattern })   
}

function patternSaveField( user, id, name, value) {
    return fetchApi('shop/pattern_save_field/', {user: user, id: id, name: name, value: value })   
}

function patternDelete( user, id) {
    return fetchApi('shop/pattern_delete/', { user: user, id: id })   
}