import { connect } from 'react-redux'

import PatternEditForm from '../../components/pattern/PatternEditForm'


const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
	};
}

 
const PatternEditAction = connect(
  mapStateToProps
)(PatternEditForm)


export default PatternEditAction;