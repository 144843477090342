import React, { Component } from 'react';
import { pie as d3Pie} from 'd3-shape';
import * as d3 from 'd3';
import ReactDOM from 'react-dom';

import {shopTypeList} from '../../constants';

import './SunBurst.css'

 
class SunBurst extends Component {
    constructor() {
        super();
 
        /*
        this.mouseover = this.mouseover.bind(this);
        this.breadcrumbPoints = this.breadcrumbPoints.bind(this);
        this.updateBreadcrumbs = this.updateBreadcrumbs.bind(this);
        this.createVisualization = this.createVisualization.bind(this);*/

        this.width = 960
        this.height = 700
        this.radius = (Math.min(this.width, this.height) / 2) - 10
        
        this.pie = d3Pie().value((d) => d.money)
        
        this.partition = d3.partition()
            .size([2 * Math.PI, this.radius * this.radius]);
        this.b = {w: 120, h: 30, s: 3, t: 10}
        
        let colors = {}
        shopTypeList.map( (shopType) => {
            colors[shopType.label] = shopType.color
            return true
        })
        this.colors = colors
        
        this.getColor = (d,position) => {
            if( d.depth === 1 ){
                return colors[d.data.name]                
            }else if( d.depth === 2) {
                // COUNTRY
                let country = this.props.countryList.filter((c) => {
                    if(c.name === d.data.name){
                        return c
                    } 
                    else{
                        return false
                    }
                })
                if( country[0] ){
                    return country[0].color  
                } 
            }else if( d.depth === 3) {
                // SHOP (Country)
                let country = this.props.countryList.filter((c) => {
                    if(c.name === d.parent.data.name){
                        return c
                    }else{
                        return false
                    }
                })
                if( country[0] ){   
                    if(d.data.name==="Taichi Vladimir"){
                        console.log(d)
                    }
                    if(d.data.name==="Groceries Kyrgyzstan"){
                        console.log(d)
                    }
                    let gradientName = country[0].name.replace(" ","_") +"-"+ d.parent.parent.data.name.replace(" ","_")
                    let gradientNameReverse =    d.parent.parent.data.name.replace(" ","_") +"-"+ country[0].name.replace(" ","_")
                    if( position === "breadcrumb"){
                        return "url(#"+ gradientName + ")" 
                    }else{
                        if( d.x0 > 4 ){
                            return "url(#"+ gradientNameReverse+ ")"
                        }
                        else{
                            return "url(#"+ gradientName + ")"   
                        }  
                    }
                    // return country[0].color  
                } 
                //return colors[d.parent.parent.data.name]                    
            }else if( d.depth === 4) {
                return colors[d.parent.parent.parent.data.name]                    
            }else{
                return colors[d.data.name] 
            }
        }

        this.getColorBreadCrumb = (d) => this.getColor(d,"breadcrumb" )
        this.getColorPie = (d) => this.getColor(d,"pie" )

        this.totalSize = 0
        this.arc = d3.arc()
            .startAngle(function(d) { return d.x0; })
            .endAngle(function(d) { return d.x1; })
            .innerRadius(function(d) { return Math.sqrt(d.y0); })
            .outerRadius(function(d) { return Math.sqrt(d.y1); });

        this.vis = ""
        this.path = {}
        
    }

    componentDidMount(){
        this.d3Graph = d3.select(ReactDOM.findDOMNode(this.refs.graph));

        this.vis =  this.d3Graph.select("#chart").append("svg:svg")
            .attr("id","chartSvg")
            .attr("width", this.width)
            .attr("height", this.height)
            .append("svg:g")
            .attr("id", "container")
            .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")");

        this.svgDefs = this.vis.append('defs');

        this.props.countryList.map((c) => {
            shopTypeList.map( (s) => {
                let gradientName = c.name.replace(" ","_") +"-"+ s.label.replace(" ","_")

                this.mainGradient = this.svgDefs.append('linearGradient')
                    .attr('id', gradientName);

                this.mainGradient.append('stop')
                    .attr("stop-color", c.color)        
                    .attr('class', 'stop-left')
                    .attr('offset', '0');

                this.mainGradient.append('stop')
                    .attr("stop-color", s.color)        
                    .attr('class', 'stop-right')
                    .attr('offset', '1');

                let gradientNameReverse =  s.label.replace(" ","_") +"-"+ c.name.replace(" ","_")

                this.mainGradient = this.svgDefs.append('linearGradient')
                    .attr('id', gradientNameReverse);

                this.mainGradient.append('stop')
                    .attr("stop-color", s.color)        
                    .attr('class', 'stop-left')
                    .attr('offset', '0');

                this.mainGradient.append('stop')
                    .attr("stop-color", c.color)        
                    .attr('class', 'stop-right')
                    .attr('offset', '1'); 

                return true
            })

            return true
        })
        
        var csv = d3.csvParseRows(this.props.csv);
        var json = this.buildHierarchy(csv);     
        this.createVisualization(json);

    }
    

    componentWillReceiveProps( nextProps ){
        this.d3Graph.selectAll("path").remove();

        var csv = d3.csvParseRows(nextProps.csv);
        var json = this.buildHierarchy(csv);     
        this.drawPie(json);
    }

    createVisualization = (json) => {
        // Basic setup of page elements.
        this.initializeBreadcrumbTrail();
        this.drawLegend();
        d3.select("#togglelegend").on("click", this.toggleLegend);

        // Bounding circle underneath the sunburst, to make it easier to detect
        // when the mouse leaves the parent g.
        this.vis.append("svg:circle")
          .attr("r", this.radius)
          .style("opacity", 0);

        this.drawPie(json)

        // Add the mouseleave handler to the bounding circle.
        d3.select("#container").on("mouseleave", this.mouseleave);        
    }

    drawPie(json){
        // Turn the data into a d3 hierarchy and calculate the sums.
        var root = d3.hierarchy(json)
            .sum(function(d) { return d.size; })
            .sort(function(a, b) { return b.value - a.value; });

        // For efficiency, filter nodes to keep only those large enough to see.
        var nodes = this.partition(root).descendants()
            .filter(function(d) {
                return (d.x1 - d.x0 > 0.005); // 0.005 radians = 0.29 degrees
            });

        var path = this.vis.data([json]).selectAll("path").data(nodes)
            .enter().append("svg:path")
            .attr("display", function(d) { return d.depth ? null : "none"; })
            .attr("d", this.arc)
            .attr("fill-rule", "evenodd")
            .style("fill", this.getColorPie )
            .style("opacity", 1)
            .on("mouseover", this.mouseover);
        
        // Get total size of the tree = value of root node from partition.
        this.totalSize = path.datum().value;   
        console.log("totalSzie",this.totalSize)         
    }

    // Fade all but the current sequence, and show it in the breadcrumb trail.
    mouseover = (d) => {
        var percentage = (100 * d.value / this.totalSize).toPrecision(3);
        var percentageString = percentage + "%";
        if (percentage < 0.1) {
            percentageString = "< 0.1%";
        }

        d3.select("#percentage")
            .text(percentageString);

        d3.select("#explanation")
            .style("visibility", "");

        var sequenceArray = d.ancestors().reverse();
        sequenceArray.shift(); // remove root node from the array
        this.updateBreadcrumbs(sequenceArray, percentageString);

        let explanationCat = ""
        sequenceArray.map( ( d ) => {
            explanationCat += d.data.name + " > "
            return true
        })

        d3.select("#explanationMoney").text(d.value.toFixed(2) +" €")
        d3.select("#explanationBreadCrumb").text(explanationCat)

        // Fade all the segments.
        d3.selectAll("path")
            .style("opacity", 0.3);

        // Then highlight only those that are an ancestor of the current segment.
        this.vis.selectAll("path")
            .filter(function(node) {
                return (sequenceArray.indexOf(node) >= 0);
              })
        .style("opacity", 1);
    }

    // Restore everything to full opacity when moving off the visualization.
    mouseleave = (d) => {
        // Hide the breadcrumb trail
        d3.select("#trail")
            .style("visibility", "hidden");

        // Deactivate all segments during transition.
        d3.selectAll("path").on("mouseover", null);

        // Transition each segment to full opacity and then reactivate it.
        let this2 = this
        d3.selectAll("path")
            .transition()
            .duration(1000)
            .style("opacity", 1)
            .on("end", function() {
                d3.select(this).on("mouseover", this2.mouseover);
            });

        d3.select("#explanation")
            .style("visibility", "hidden");
    }

    initializeBreadcrumbTrail() {
        // Add the svg area.
        var trail = d3.select("#sequence").append("svg:svg")
            .attr("width", this.width)
            .attr("height", 50)
            .attr("id", "trail");
        
        // Add the label at the end, for the percentage.
        trail.append("svg:text")
            .attr("id", "endlabel")
            .style("fill", "#000");
    }

    // Generate a string that describes the points of a breadcrumb polygon.
    breadcrumbPoints = (d, i) => {
        var points = [];
        points.push("0,0");
        points.push(this.b.w + ",0");
        points.push(this.b.w + this.b.t + "," + (this.b.h / 2));
        points.push(this.b.w + "," + this.b.h);
        points.push("0," + this.b.h);
        if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
            points.push(this.b.t + "," + (this.b.h / 2));
        }
        return points.join(" ");
    }

    // Update the breadcrumb trail to show the current sequence and percentage.
    updateBreadcrumbs = (nodeArray, percentageString) => {
        // Data join; key function combines name and depth (= position in sequence).
        var trail = d3.select("#trail")
            .selectAll("g")
            .data(nodeArray, function(d) { return d.data.name + d.depth; });

        // Remove exiting nodes.
        trail.exit().remove();

        // Add breadcrumb and label for entering nodes.
        var entering = trail.enter().append("svg:g");

        entering.append("svg:polygon")
            .attr("points", this.breadcrumbPoints)
            .style("fill", this.getColorBreadCrumb );

        entering.append("svg:text")
            .attr("x", (this.b.w + this.b.t) / 2)
            .attr("y", this.b.h / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "middle")
            .text(function(d) { return d.data.name; });

        // Merge enter and update selections; set position for all nodes.
        let b2 = this.b
        entering.merge(trail).attr("transform", function(d, i) {
            return "translate(" + i * (b2.w + b2.s) + ", 0)";
        });

        // Now move and update the percentage at the end.
        d3.select( "#trail").select("#endlabel")
            .attr( "x", (nodeArray.length + 0.5) * (this.b.w + this.b.s) )
            .attr( "y", this.b.h / 2 )
            .attr( "dy", "0.35em" )
            .attr( "text-anchor", "middle" )
            .text( percentageString );

        // Make the breadcrumb trail visible, if it's hidden.
        d3.select("#trail")
            .style("visibility", "");
    }

    drawLegend = () => {
        // Dimensions of legend item: width, height, spacing, radius of rounded rect.
        var li = {
            w: 100, h: 30, s: 3, r: 3
        };

        var legend = d3.select("#legend").append("svg:svg")
            .attr("width", li.w)
            .attr("height", d3.keys(this.colors).length * (li.h + li.s));

        var g = legend.selectAll("g")
            .data(d3.entries(this.colors))
            .enter().append("svg:g")
            .attr( "transform", function(d, i) {
                return "translate(0," + i * (li.h + li.s) + ")";
            });

        g.append("svg:rect")
            .attr("rx", li.r)
            .attr("ry", li.r)
            .attr("width", li.w)
            .attr("height", li.h)
            .style("fill", function(d) { return d.value; });

        g.append("svg:text")
            .attr( "x", li.w / 2 )
            .attr( "y", li.h / 2 )
            .attr( "dy", "0.35em" )
            .attr( "text-anchor", "middle" )
            .text( function(d){ 
                return d.key
            });
    }

    toggleLegend = () => {
        var legend = d3.select("#legend");
        if (legend.style("visibility") === "hidden") {
            legend.style("visibility", "");
        } else {
            legend.style("visibility", "hidden");
        }
    }

    // Take a 2-column CSV and transform it into a hierarchical structure suitable
    // for a partition layout. The first column is a sequence of step names, from
    // root to leaf, separated by hyphens. The second column is a count of how 
    // often that sequence occurred.
    buildHierarchy(csv) {
        var root = {"name": "root", "children": []};
        for (var i = 0; i < csv.length; i++) {
            var sequence = csv[i][0];
            var size = +csv[i][1];
            if (isNaN(size)) { // e.g. if this is a header row
                continue;
            }
            var parts = sequence.split("-");
            var currentNode = root;
            for (var j = 0; j < parts.length; j++) {
                var children = currentNode["children"];
                var nodeName = parts[j];
                var childNode;
                if (j + 1 < parts.length) {
                    // Not yet at the end of the sequence; move down the tree.
                    var foundChild = false;
                    for (var k = 0; k < children.length; k++) {
                        if (children[k]["name"] === nodeName) {
                            childNode = children[k];
                            foundChild = true;
                            break;
                        }
                    }
                    
                    // If we don't already have a child node for this branch, create it.
                    if (!foundChild) {
                        childNode = {"name": nodeName, "children": []};
                        children.push(childNode);
                    }
                    currentNode = childNode;
                } else {
                    // Reached the end of the sequence; create a leaf node.
                    childNode = {"name": nodeName, "size": size };
                    children.push(childNode);
                }
            }
        }
        return root;
    };

    render() { 
        return (
        <div id="sunBurst" ref='graph'>    
            <div id="main">
                <div id="sequence"></div>
                <div id="chart" >
                    <div id="explanation" style={{visibility: "hidden"}} >
                        <span id="percentage"></span><br/>
                        <strong id="explanationMoney"></strong><br/>
                        invested on <br/>
                        <span id="explanationBreadCrumb"></span>
                    </div>
                </div>
            </div>
            <div id="sidebar">
                <input type="checkbox" id="togglelegend" /> Legend<br/>
                <div id="legend" style={{visibility: "hidden"}} ></div>
            </div>
        </div>
        )
    }
}
 
export default SunBurst;