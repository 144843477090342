import {cityService} from '../services';
import {fetchShopSaveField} from '../actions';

import { alertActions } from './';

export const cityActions = {
    load,
    add,    
    _delete,
}

export const CITY_LOAD_REQUEST = 'CITY_LOAD_REQUEST'
export const CITY_LOAD_SUCCESS = 'CITY_LOAD_SUCCESS'
export const CITY_LOAD_FAILURE = 'CITY_LOAD_FAILURE'

function load( user ) {
    return dispatch => {
        dispatch(request( ));   
        cityService.loadList( user )
            .then(
                response => { 
                  dispatch(success(response));
                  //dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: CITY_LOAD_REQUEST } 
    }
    function success(rows) { return { type: CITY_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: CITY_LOAD_FAILURE, error } }
}

export const CITY_ADD_REQUEST = 'CITY_ADD_REQUEST'
export const CITY_ADD_SUCCESS = 'CITY_ADD_SUCCESS'
export const CITY_ADD_FAILURE = 'CITY_ADD_FAILURE'

function add( user, city, shop_id) {    
    return dispatch => {
        dispatch(request( user, city, shop_id ));   
        cityService.add( user, city )
            .then(
                response => { 
                    dispatch(success(response, shop_id));
                    if( shop_id ){
                        dispatch(fetchShopSaveField(user, shop_id, "city_id", response.id ))
                    } 
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( city ) { 
        return { type: CITY_ADD_REQUEST, city } 
    }
    function success(rows, shop_id) { return { type: CITY_ADD_SUCCESS, rows, shop_id } }
    function failure(error) { return { type: CITY_ADD_FAILURE, error } }
}

export const CITY_DELETE_REQUEST = 'CITY_DELETE_REQUEST'
export const CITY_DELETE_SUCCESS = 'CITY_DELETE_SUCCESS'
export const CITY_DELETE_FAILURE = 'CITY_DELETE_FAILURE'

function _delete( user, id ) { 
    return dispatch => {
        dispatch(request( id ));   
        cityService._delete( user, id )
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: CITY_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: CITY_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: CITY_DELETE_FAILURE, error } }
}