import React, {Component} from 'react';

import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import ShopEditContainer from '../../../../components/shop/shopEditContainer/ShopEditContainer';

import Slide from '@material-ui/core/Slide';



const styles = theme => ({	
	content: {
		marginTop: theme.spacing(8),
	}
})

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ShopDialog extends Component {	
	constructor (props) {
		super(props)
		
		this.state = {
			imageExpanded: false,
		}
	}	

	handleImageExpanded = () => {
    	this.setState({ imageExpanded: !this.state.imageExpanded });
  	}
	setImageHeight = () => {
  		return ( this.state.imageExpanded )? "100vh":"50vh"
  		//return ( this.state.imageExpanded )? window.innerHeight +"px":"50vh"
  	}
	render() {		
		const {classes,shop,open,handleClose} = this.props;
		return <Dialog fullScreen open={open}
					onClose={handleClose}
					TransitionComponent={Transition}>
         <AppBar className={classes.appBar}>
            <Toolbar>
              	<IconButton color="inherit" onClick={handleClose} aria-label="Close">
               		<CloseIcon />
              	</IconButton>
              	<Typography variant="h6" color="inherit" className={classes.flex}>
               		{shop.name}
              	</Typography>
              	<Button color="inherit" onClick={handleClose}>
               		Delete
              	</Button>
            </Toolbar>
        </AppBar>
		<DialogContent className={classes.content}>
			<ShopEditContainer
				{...this.props}
				shop={shop} />

         </DialogContent>
		</Dialog>
	}
}

export default withStyles(styles)(ShopDialog)