import React, { Component } from 'react';

import Axes from '../../chart/Axes'
import Bars from '../../chart/Bars'
import IndicatorLine from '../../chart/IndicatorLine'

import { stack } from 'd3-shape'


import ResponsiveWrapper from '../../chart/ResponsiveWrapper'

import { scaleBand, scaleLinear } from "d3-scale"
import { max } from "d3-array"
import "d3-transition"

import {shopTypeList} from '../../../constants';

import {getBillTotal,isBillFilter,isEmpty} from '../../../functions';

var moment = require("moment");

//import './ChartDays.css'

class ChartMoneyDayCompensate extends Component {
  
  	constructor(props) {
   	super(props);
    	this.xScale = scaleBand()
    	this.yScale = scaleLinear()
  	}


  	render() {
  		const {billList, shopList,filter} = this.props
  		let columns = {}
  		let columnsTotal = {}

		const colors = shopTypeList.map( st => {
			return st.color
		})
		const label = shopTypeList.map( st => {
			return {
				key: st.key,
				label: st.label,
				color: st.color,
			}
		})

  		Object.keys(billList).map(i => {
  			const bill = billList[i]
  			const shop = shopList[bill.shop_id]

         let som = getBillTotal( bill, shop )
         var d = moment(bill.date);         
         const key = d.format("YYYYMMDD")

  			if( isBillFilter( bill ,shop, filter ) ){  
  				columns[key] = columns[key] || {}
  				columnsTotal[key] = columnsTotal[key] || 0
  				columns[key][shop.type] = columns[key][shop.type] || {}

            if( isEmpty( columns[key][shop.type]) ){
               columns[key].date = d
               columns[key][shop.type].money = 0
            }

	         columnsTotal[key] += som
	         columns[key][shop.type].money += som
         }

         return true
  		})
  		
  		var data = []
  		var curDate = moment(filter.from)
  		while( curDate.isBetween( moment(filter.from) , moment(filter.to),null,"[]")){
  			const key = curDate.format("YYYYMMDD")
  			if(!columns[key]){
  				data.push({
  					label: curDate.format("DD-MM-YYYY"),
  				})
  			}else{
  				let col = columns[key]

  				let colDay = {
 					label:	curDate.format("DD-MM-YYYY"),
 				}
 			
 				shopTypeList.map( st => {
 					if( col[st.key] && col[st.key].money ){
 						colDay[st.key] = col[st.key].money
 					}else{
 						colDay[st.key] = col[st.key] || 0
 					}
        			return true
 				})

 				data.push(colDay)
  			}
  			curDate.add(1,'days')
  		}

		const stacked = stack()
      	.keys(shopTypeList.map( st => st.key))

		const stackedData = stacked(data)		
		const margins = { top: 12, right: 12, bottom: 48, left: 48 }
		const svgDimensions = {
   		width: Math.max(this.props.parentWidth, 300),
   		height: 300
      }


		const maxValue = max(Object.keys(columnsTotal).map( i => {
			let col = columnsTotal[i]
			return col
		})) + 20

		const xScale = this.xScale
			.padding(0.5)
			.domain(stackedData[0].map(d => d.data.label))
			.range([margins.left, svgDimensions.width - margins.right])

		const yScale = this.yScale
			.domain([0,maxValue])
			.range([svgDimensions.height - margins.bottom, margins.top])

		return (
			<svg width={svgDimensions.width} height={svgDimensions.height}>
			   <Axes
          		scales={{ xScale, yScale }}
          		margins={margins}
          		svgDimensions={svgDimensions}
        		/>
        		
            <IndicatorLine 
        			score={yScale(25)} 
        			color="#FF0000"
        			svgDimensions={svgDimensions}
        			margins={margins} />
        		<IndicatorLine 
        			score={yScale(25 - 10)} 
        			color="#00FF00"
        			svgDimensions={svgDimensions}
        			margins={margins} />
        		
        		<Bars
          		scales={{ xScale, yScale }}
          		margins={margins}
          		colors={colors}
          		label={label}
          		data={stackedData}
          		maxValue={maxValue}
          		svgDimensions={svgDimensions}
        		/>
			</svg>
		)
  	}
}

export default ResponsiveWrapper(ChartMoneyDayCompensate)