import {fetchApi} from '../helpers';

export const billService = {
    loadList,
    loadListByYear,
    add,
    _delete,
    inspect,
    digestRegion,
    readRegions,
};

function loadList( user ) {
    return fetchApi('bill/get_list', {user: user})   
}

function loadListByYear( user, year ) {
    return fetchApi('bill/get_list', {user: user, year: year})   
}

function add( user, bill ) {
    return fetchApi('bill/add', {
        user: user,
        bill: bill,
    })   
}


function _delete( user, id ) {
    return fetchApi('bill/delete', {user: user, id: id})   
}

function inspect( user, id ) {
    return fetchApi('bill/inspect', {user: user, id: id})   
}


function digestRegion( user, id ){
	return fetchApi('bill/digest_region', {user: user, id: id})   
};

function readRegions( user, billId, regions ){
	return fetchApi('bill/read_regions', {user: user, bill_id: billId, region_list: regions})   
};