import React, {Component} from 'react';
import { connect } from 'react-redux'

import ShopMenu from './shopMenu/ShopMenu'

class ShopList extends Component { 
	constructor(props) {
    	super(props)
    	this.state = {
    		openAddDialog : false,
    	}
  	}

  	handleClickOpenAdd = () => {
    	this.setState({ openAddDialog: true });
  	};

  	handleCloseAdd = () => {
    	this.setState({ openAddDialog: false });
  	};

	render(){
		var props = this.props
		if( ! props.shopList ) return false
		if( ! props.countryList ) return false
		if( ! props.cityList ) return false	

		let list = props.shopList;
		let cityCountry = {}

		Object.keys(list).sort((a, b) =>  {
				if( list[a].name <= list[b].name ){
					return 1;
				}else{
					return -1;
				}
			})
			.reverse()
			.map( index => {
				let shop = list[index]
				if( shop.country_id && parseInt(shop.country_id,10) !== 0 ){
					let countryId = shop.country_id
					if( !cityCountry[countryId]){
						cityCountry[countryId] = {
							country: props.countryList.find( c => c.id === countryId ),
							list: {
								city: {},
								general: []
							}
						}
					}
					if( shop.city_id && parseInt(shop.city_id,10) !== 0){
						let cityId = shop.city_id
						if( !cityCountry[countryId].list.city[cityId]){
							cityCountry[countryId].list.city[cityId] = {
								city: props.cityList.find( c => {
									if( c )
										return c.id === cityId 
									else return 0}),
								list: []
							}
						}
						cityCountry[countryId].list.city[cityId].list.push(shop)
					}else{
						cityCountry[countryId].list.general.push( shop )
					}
				}
				return true
			})	

		
		return <ShopMenu 
			cityCountry={cityCountry}
			handleClickOpenAdd={this.handleClickOpenAdd}
			handleCloseAdd={this.handleCloseAdd}
			openAddDialog={this.state.openAddDialog} />
	}

}

const mapStateToProps = (state,ownProps)  => {  
  return {
    auth: state.authentication,   
    shopList: state.shop.list,
    countryList: state.country.list,
	cityList: state.city.list,
  }
}

ShopList = connect(mapStateToProps)(ShopList)
 
export default ShopList