import { connect } from 'react-redux'

import ProductDetAddForm from '../components/productDet/ProductDetAddForm'

import { fetchProductDetAdd } from '../actions/productDet'

const mapStateToProps = (state, ownProps) => {
	return {
		...state,
		...ownProps,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onAdd: (pattern,productDetId) => dispatch( fetchProductDetAdd(pattern,productDetId) ),
	}
}
 
const ProductDetAdd = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetAddForm)


export default ProductDetAdd;