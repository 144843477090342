import React, {Component} from 'react';

import Hidden from '@material-ui/core/Hidden';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles'


import {isEmpty} from '../../../../functions';

import BillDialog from '../billDialog/BillDialog';
import BillImage from '../billImage/BillImage';

import BillRegion from '../../../../containers/bill/BillRegion';


import InvoiceContainer from '../../../../components/invoice/invoiceContainer/InvoiceContainer';

const styles = theme => ({
	invoiceText: {
		padding: "12px 24px",
	}
})

class Bill extends Component {	
	constructor (props) {
		super(props)
		
		this.state = {
			open: true,
		}
	}

	handleClickOpen = () => {
    	this.setState({ open: true });
  	};

  	handleClose = () => {
    	this.setState({ open: false });
  	};	

	render() {
		if( isEmpty(this.props.invoice) ) return false
		
		const {classes,invoice} = this.props
		const {open} = this.state

		return 	<div>
			<Hidden smDown>
				<Grid container className={classes.container} justify="center">	
					<Grid item xs={12} sm={7} className={classes.invoiceText}>
						<InvoiceContainer
							billId={invoice.bill_id}
							invoice={invoice}
						/>

						<div className="card" >
							<div className="card-body">
								{(invoice.region)?invoice.region.map( (region) => (
									<BillRegion key={region.id} region={region} bill={invoice}></BillRegion>
									)):""
								}
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={5} className={classes.invoice_image}>					
						<div className="img-col">														
    						<BillImage invoice={invoice}
    							handleInspectClick={this.props.handleInspectClick}
    							handleReadRegionsClick={this.props.handleReadRegionsClick} />
						</div>
					</Grid>
				</Grid>
			</Hidden>
			<Hidden mdUp>
				<BillDialog invoice={invoice} 
					open={open} handleClose={this.handleClose}
					readBill={this.readBill}
					handleInspectClick={this.props.handleInspectClick}
    				handleReadRegionsClick={this.props.handleReadRegionsClick} />
    		</Hidden>
		</div>
	}
}

export default withStyles(styles)(Bill)