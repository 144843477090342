import React, {Component} from 'react';

import {shopTypeList} from '../../../../constants/constants';

class ShopAdd extends Component {	

	render(){
		const {shop,handleChange,handleSubmit,countryList} = this.props
		
		return (
			<div>
				<div className="form-group">
					<label>Type</label>
					<select className="form-control" name="type" 
						value={shop.type} onChange={handleChange} >
						<option value="">-</option>
						{
							shopTypeList.map( (shopType) => (
								<option key={shopType.key} value={shopType.key}>{shopType.label}</option>
							))
						}
					</select>					    	
		  		</div> 
				<div className="form-group">
					<label>Name</label>
					<input type="text" className="form-control" placeholder="Shop Name"
				    	name="name" value={shop.name} onChange={handleChange}/>
				</div> 
				<div className="form-group">
			    	<label>Country</label>
			    	<select className="form-control" name="country_id" 
			    		value={shop.country_id} 
			    		onChange={handleChange}>
			    		<option value="">-</option>
			    		{countryList.map( (country) => (
							<option key={country.id} 
							value={country.id}>{country.name}</option>
							))
			    		} 								
					</select>					    	
			  	</div>			  	
				<div>
					<button type="button" className="btn btn-primary"
						onClick={handleSubmit}
  						>Add</button>
				</div>
				
		  	</div>
		);
	}
}


export default ShopAdd;