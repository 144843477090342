import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

import Select from 'react-select';
import DialogActions from '@material-ui/core/DialogActions';

class BillAdd extends Component {	

	render(){
		const {bill,handleChange,handleSubmit,shopList,handleSelectChange,handleInputChange } = this.props
		return (
			<div>
				<div className="form-group">
					<label>Shop</label>
			    	<Select
						name="shop_id"
						options={Object.keys(shopList).map( (i) => {
							return (
								{ value: shopList[i].id, label: shopList[i].name }
							);
						})}
						onInputChange={handleInputChange}
						onChange={handleSelectChange}
					/>
				</div>
			  	<div className="form-group">
					<label>Date</label>
					<input type="date" className="form-control" 
						name="date" value={bill.date}  
						onChange={handleChange}
						/>
				</div>
			
				<div className="form-group">
				    <label>Total</label>
				    <div className="input-group">
		    			<input type="text" className="form-control" placeholder="Total"
		    				name="total" value={bill.total} 
		    				onChange={handleChange} />
		    			<div className="input-group-append">
							<span className="input-group-text">$</span>
						</div>
					</div>
  				</div>
  				<DialogActions>
					<Button color="secondary" onClick={handleSubmit}>
						Add
					</Button>
				</DialogActions>
		  	</div>
		);
	}
}


export default BillAdd;