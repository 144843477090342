import React, { Component } from 'react';

import Axes from '../../chart/Axes'
import Bars from '../../chart/Bars'
import { stack } from 'd3-shape'
import IndicatorLine from '../../chart/IndicatorLine'

import ResponsiveWrapper from '../../chart/ResponsiveWrapper'

import { scaleBand, scaleLinear } from "d3-scale"
import { max } from "d3-array"
import "d3-transition"

import {getBillTotal,isBillFilter,isEmpty,getCompensate} from '../../../functions';

var moment = require("moment");

//import './ChartDays.css'

class ChartMoneyDayCompensate extends Component {
  
  	constructor(props) {
   	super(props);
    	this.xScale = scaleBand()
    	this.yScale = scaleLinear()
  	}


  	render() {
  		const {billList, shopList,filter} = this.props
  		let columns = {}

  		const colors = ['#0000FF', '#FF0000']
		const label = [{
				key: 'score',
				label: 'Day Expenses',
				color: '#0000FF',
			},
			{
				key: 'compensate',
				label: 'To Compensate',
				color: '#FF0000',
			}]

  		Object.keys(billList).map(i => {
  			const bill = billList[i]
  			const shop = shopList[bill.shop_id]

         let som = getBillTotal( bill, shop )
         var d = moment(bill.date);         
         const key = d.format("YYYYMMDD");

  			if( isBillFilter( bill ,shop, filter ) ){
            if( isEmpty( columns[key]) ){
                columns[key] = {}
                columns[key].date = d
                columns[key].money = 0
            }
            if( parseInt(bill.days_compensate,10) === 0 ){
	             columns[key].money += som;                
	         }
         }                     
         return true
  		})
		const compensate = getCompensate(billList, shopList, filter)

		var data = []
  		var curDate = moment(filter.from)
  		while( curDate.isBetween( moment(filter.from) , moment(filter.to),null,"[]")){
  			const key = curDate.format("YYYYMMDD")
  			let col = columns[key]

  			var money = 0
  			if(columns[key]){
 				money = col.money
  			}
			
			let moneyCompensate = 0;
        	compensate.map( (c) => {
            if( curDate.isBetween(c.ini, c.end ) ){
                moneyCompensate += c.amount
            }
            return true
        	})

  			data.push({
  				label:	curDate.format("DD-MM-YYYY"),
  				score: 	money,
  				compensate: moneyCompensate,
  				total: money + moneyCompensate,
  			})

  			curDate.add(1,'days')
  		}

		const stacked = stack()
      	.keys(['score', 'compensate'])

		const stackedData = stacked(data)


		const margins = { top: 12, right: 12, bottom: 48, left: 48 }
		const svgDimensions = {
   		width: Math.max(this.props.parentWidth, 300),
   		height: 300
      }

		const maxValue = max(stackedData[0], d => d.data.total) + 20


		const xScale = this.xScale
			.padding(0.5)
			.domain(stackedData[0].map(d => d.data.label))
			.range([margins.left, svgDimensions.width - margins.right])

		const yScale = this.yScale
			.domain([0,maxValue])
			.range([svgDimensions.height - margins.bottom, margins.top])

		return (
			<svg width={svgDimensions.width} height={svgDimensions.height}>
			   <Axes
          		scales={{ xScale, yScale }}
          		margins={margins}
          		svgDimensions={svgDimensions}
        		/>

          <IndicatorLine 
              score={yScale(25)} 
              color="#FF0000"
              svgDimensions={svgDimensions}
              margins={margins} />
            <IndicatorLine 
              score={yScale(25 - 10)} 
              color="#00FF00"
              svgDimensions={svgDimensions}
              margins={margins} />
              
        		<Bars
          		scales={{ xScale, yScale }}
          		margins={margins}
          		data={stackedData}
          		label={label}
          		colors={colors}
          		maxValue={maxValue}
          		svgDimensions={svgDimensions}
        		/>
			</svg>
		)
  	}
}

export default ResponsiveWrapper(ChartMoneyDayCompensate)