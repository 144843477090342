import {
  AUTHENTICATION_LOGIN_REQUEST,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGIN_FAILURE,
  AUTHENTICATION_LOGOUT,
} from '../actions/authentication'

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case AUTHENTICATION_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case AUTHENTICATION_LOGIN_FAILURE:
      return {};
    case AUTHENTICATION_LOGOUT:
      return {};
    default:
      return state
  }
}

export default authentication