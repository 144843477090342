import React, {Component} from 'react';
import { connect } from 'react-redux'
import { alertActions } from '../actions';

import { history } from '../helpers/history';


class AlertContainer extends Component { 
	
	constructor(props) {
        super(props);

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

  	render(){
  		const { alert } = this.props;
  		if( !alert.message ) return false
  		
  		return <div 
  			className={`alert ${alert.type}`}
  			style={{margin: "12px"}}
  			>{alert.message}</div>
  	}
}

const mapStateToProps = (state, ownProps) => {
	const { alert } = state;
	return {
		alert: alert,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);  