import React, {Component} from 'react';
import { connect } from 'react-redux'

import Bill from './bill/Bill';
import { fetchBillInspect,fetchBillReadRegions } from '../../../actions'

import { db2region} from '../../../functions';

import update from 'immutability-helper';


class BillContainer extends Component {	
	constructor (props) {
		super(props);
		
		this.state = {
			invoice: {},
			render_regions: [],
		};
	}

	componentWillMount( ){
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const billId =	nextProps.billId
		if( nextProps.invoice ){			
			let invoice = nextProps.invoice[billId]
			let render_regions = (invoice)?invoice.region.map( (r) => ( db2region(r) ) ):""

			let newState = update( this.state, {
				invoice: {$set: invoice},
				render_regions: {$set: render_regions},
			});
			this.setState( newState );
		}
	}	

	handleInspectClick = ( billId ) => {
		if( !this.props.onInspectClick) return false
		this.props.onInspectClick( this.props.auth.user, billId ) 
	}

	handleReadRegionsClick = ( billId, regions ) => {
		if( !this.props.onReadRegionsClick) return false
		this.props.onReadRegionsClick( this.props.auth.user, billId, regions ) 
	}

  	render(){
  		const {invoice, render_regions} = this.state
  		if( !invoice ) return false

		return <Bill invoice={invoice} render_regions={render_regions}
			handleInspectClick={this.handleInspectClick}
			handleReadRegionsClick={this.handleReadRegionsClick} />
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
      	auth: state.authentication,     
  	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onInspectClick: (user, id) => dispatch( fetchBillInspect(user, id)  ),
		onReadRegionsClick: (user, billId, regions) => dispatch( fetchBillReadRegions(user, billId,regions)  )
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(BillContainer);  