import React from 'react';

import PatternEditAction from '../../containers/pattern/PatternEditAction';
import PatternAdd from '../../containers/pattern/PatternAdd';


const PatternForm = (props) => {
	if( !props.patternList ) return false
	
	const patternList = props.patternList	

	return (
		<div className="card">
			<div className="card-body">
				{Object.keys(patternList).map( (i) => (
					<PatternEditAction 
						key={patternList[i].id}
						pattern={patternList[i]}						
						onSaveField={ (user,id,name,value) => props.onSaveField(user,id,name,value) }
						onDelete={ (user,id) => props.onDelete(user,id) }
						productList={props.productList}
						productDetId={props.id} />
				))} 				
			</div>
			<div className="card-footer">				
				<PatternAdd 
					onAdd={ (user,pattern,relId) => props.onAdd(user,pattern,relId) }
					relId={props.id} />
			</div>
		</div>
	)
}

export default PatternForm;