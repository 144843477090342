import React, {Component} from 'react';

import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class PatternEditForm extends Component {
	constructor (props) { 		
		super(props);
				
		this.state = {
			pattern: [],		
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentWillMount(  ){
		this.setState({
			pattern: this.props.pattern
		});	
	}

	componentWillReceiveProps( newProps ){
		this.setState({
			pattern: newProps.pattern
		});
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			pattern: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleBlur( event ){
  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.props.onSaveField( this.props.auth.user, this.props.pattern.id, name, value )
  	}	

	render(){
		const pattern =	this.state.pattern;

		return ( <div className="row">
			<div className="col-5">
				<input name="label" value={pattern.label}
					className="form-control" placeholder="Label"
					onChange={this.handleChange}
					onBlur={this.handleBlur} />
			</div>
			<div className="col-5">
				<input name="pattern" value={pattern.pattern}
					className="form-control" placeholder="Pattern" 
					onChange={this.handleChange}
					onBlur={this.handleBlur}/>
			</div>
			<div className="col-2">
				<button className="btn btn-danger" style={{width: '40px'}} 
					onClick={() => { if (window.confirm('Are you sure you wish to delete this pattern?')) this.props.onDelete(this.props.auth.user, pattern.id ) } }>
					<FontAwesomeIcon icon="trash-alt" />
				</button>
			</div>
		</div>);

		
	}
}

export default PatternEditForm;