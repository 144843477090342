import { connect } from 'react-redux'

import ProductForm from '../components/product/ProductForm'

import { fetchProductSave,fetchProductDelete} from '../actions/product'

const mapStateToProps = (state, ownProps) => {
	return {
		invoice: state.invoice,
		productList: state.productList,
		...ownProps,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSave: (invoice) => dispatch( fetchProductSave(invoice) ),
		onDelete: (id) => dispatch( fetchProductDelete(id) )
	}
}
 
const ProductAction = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductForm)


export default ProductAction;