import React, {Component} from 'react';

import ShopDialog from '../shopDialog/ShopDialog';


import ShopDeleteContainer from '../../../shopContainer/ShopDeleteContainer';
import ShopEditContainer from '../../../shop/shopEditContainer/ShopEditContainer';
import PatternShopContainer from '../../../../containers/pattern/PatternShopContainer'
import InvoiceSummaryContainer from '../../../../containers/invoice/InvoiceSummaryContainer.js';
import InvoiceZoomable from '../../../invoice/InvoiceZoomable';

import Hidden from '@material-ui/core/Hidden';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	invoiceText: {
		padding: "24px",
	}
})

class ShopView extends Component {	
	constructor (props) {
		super(props);
		
		this.state = {
			open: true,
			shopId: "",
			invoice: {},
			render_regions: [],
		};
	}
	handleClickOpen = () => {
    	this.setState({ open: true });
  	}

  	handleClose = () => {
    	this.setState({ open: false });
  	}

  	componentWillMount(){
  		this.componentWillReceiveProps( this.props )
  	}
  	
	componentWillReceiveProps( newProps ){
		if( !newProps.shopId ) return false
		
		const shopId =	newProps.shopId

		if( shopId !== this.props.shopId){
			this.setState({
				open: true,
				shopId: shopId
			})
		}
	}

	render(){
		const {classes} = this.props


		if( !this.props.shop || !this.props.shop[this.props.shopId]) return false
		
		const shop = this.props.shop[this.props.shopId]
	    
	    return <div>
	    	<Hidden smDown>
	    		<Grid container className={classes.container} justify="center">
		    		<Grid item xs={12} sm={7} className={classes.invoiceText}>	
						<div className="card">
							<div className="card-header">
								{shop.name} 
								<div className="float-right">
									<ShopDeleteContainer id={shop.id} />
								</div>
							</div>
							<div className="card-body">	
								<ShopEditContainer 
									shopId={shop.id} 
									countryList={this.props.countryList}
									cityList={this.props.cityList}
									auth={this.props.auth} 
									onSaveField={(user,id,name,value) => this.props.onSaveField(user,id,name,value)}
									onCityAdd={(user,city,shop_id) => this.props.onCityAdd(user,city,shop_id)}/>						
							</div>
							<div className="card-footer">
								<PatternShopContainer patternList={shop.pattern_list} id={shop.id}/>					
							</div>
						</div>
						{shop.bill_list.map( (billId) => {
							return <InvoiceSummaryContainer 
								key={billId}
								billId={billId}
								onShowInvoice={(src) => this.props.onShowInvoice(src)}/>
						})}
					</Grid>
					<Grid item xs={12} sm={5}>
						<InvoiceZoomable 
							width="400"
							height="900"
							src={this.props.zoomableSrc}
						/>
					</Grid>
				</Grid>
			</Hidden>
			<Hidden mdUp>
				<ShopDialog 				
					open={this.state.open} 
					handleClickOpen={this.handleClickOpen} handleClose={this.handleClose} 
					shop={shop} 
					countryList={this.props.countryList}
					cityList={this.props.cityList}
					auth={this.props.auth} 
					onSaveField={(user,id,name,value) => this.props.onSaveField(user,id,name,value)}
					onCityAdd={(user,city,shop_id) => this.props.onCityAdd(user,city,shop_id)}/>
    		</Hidden>    		
    	</div>
	}
}
export default withStyles(styles)(ShopView)