import {
  BILL_ADD_REQUEST,
  BILL_ADD_SUCCESS,
  BILL_ADD_FAILURE,  

  BILL_DELETE_REQUEST,
  BILL_DELETE_SUCCESS,
  BILL_DELETE_FAILURE,  


  REQUEST_BILL_LIST,
  RECEIVE_BILL_LIST,
  REQUEST_BILL_INSPECT,
  RECEIVE_BILL_INSPECT,
  REQUEST_BILL_READ_REGIONS,
  RECEIVE_BILL_READ_REGIONS,
  REQUEST_BILL_DIGEST,
  RECEIVE_BILL_DIGEST,
} from '../actions'

import {
  REQUEST_INVOICE_GET,
  RECEIVE_INVOICE_GET,  
  REQUEST_INVOICE_SAVE_FIELD,
  RECEIVE_INVOICE_SAVE_FIELD,  
} from '../actions/invoice'

import {
  REQUEST_PRODUCT_BILL_DELETE,
  RECEIVE_PRODUCT_BILL_DELETE,  
} from '../actions/productBill'

import update from 'immutability-helper';


const bill = (state = [], action) => {
  let newState = "";
  switch (action.type) {
    case REQUEST_BILL_LIST:
      return Object.assign({}, state, {
        isFetchingList: true,
      })
    case RECEIVE_BILL_LIST:
      return Object.assign({}, state, {
        isFetchingList: false,
        list: action.list,
      })
   
    case REQUEST_INVOICE_GET:
      return Object.assign({}, state, {
        isFetching: true,

      })
    case RECEIVE_INVOICE_GET:
      if( state.invoice){
        newState = update( state, {
          isFetching: {$set: false},
          invoice:{ [action.invoice.bill_id]: {$set: action.invoice} },
        })
      } else{
        newState = update( state, {
          isFetching: {$set: false},
          invoice: {$set: { [action.invoice.bill_id]: action.invoice } },
        });
      }
      return newState

    case BILL_ADD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case BILL_ADD_SUCCESS:
      newState = update( state, {
          isFetching: {$set: false},
          list: {
            [action.rows.id]: {$set: action.rows},
          }
        });      
      return newState
    case BILL_ADD_FAILURE:
      return {} 

    case REQUEST_BILL_INSPECT:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_BILL_INSPECT:
      newState = update( state, {
          isFetching: {$set: false},
          list:{
            [action.invoice.bill_id]: {$set: action.invoice}
          },
          invoice:{
            [action.invoice.bill_id]: {$set: action.invoice}
          }
        });
      return newState

    case REQUEST_BILL_DIGEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_BILL_DIGEST:
      newState = update( state, {
          isFetching: {$set: false},
          list:{
            [action.invoice.bill_id]: {$set: action.invoice}
            },
          invoice:{
            [action.invoice.bill_id]: {$set: action.invoice}
            }
        });
      return newState

    case REQUEST_PRODUCT_BILL_DELETE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_BILL_DELETE:
      newState = update( state, {
          isFetching: {$set: false},
          invoice:{
            [action.billId]: {
              product_list: {$unset: [action.id]}
            }
          }
        });
      return newState

    case REQUEST_BILL_READ_REGIONS:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_BILL_READ_REGIONS:
      newState = update( state, {
          isFetching: {$set: false},
          list:{
            [action.invoice.bill_id]: {$set: action.invoice}
            },
          invoice:{
            [action.invoice.bill_id]: {$set: action.invoice}
            }
        });
      return newState
    
    case BILL_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case BILL_DELETE_SUCCESS:    
      newState = update( state, {
          isFetching: {$set: false},
          list: {$unset: [action.rows]},
          invoice: {$unset: [action.rows]},
        });
      return newState
    case BILL_DELETE_FAILURE:
      return {} 
    
   
    case REQUEST_INVOICE_SAVE_FIELD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_INVOICE_SAVE_FIELD:
      newState = update( state, {
          isFetching: {$set: false},
          invoice:{
            [action.invoice.bill_id]: {$set: action.invoice}
          },
          list:{
            [action.invoice.bill_id]: {$set: action.invoice}
            }
        });
      return newState 
    default:
      return state
  }
}

export default bill