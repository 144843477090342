import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import ProductEditForm from '../../components/product/ProductEditForm'
import ProductDelete from '../../containers/product/ProductDelete.js';
import InvoiceSummaryContainer from '../../containers/invoice/InvoiceSummaryContainer.js';
import InvoiceZoomable from '../../components/invoice/InvoiceZoomable';
import ProductAdd from '../../containers/product/ProductAdd';

import { fetchProductGet, fetchProductSaveField} from '../../actions'

class ProductView extends Component { 
	
	constructor(props) {
    	super(props)
    	this.state = {
    		productId: "",
    		zoomableSrc: "",
    	}
  	}

  	getProduct( productId ){
		let newState = update( this.state, {
			productId: {$set: productId},
		});
		this.setState( newState );	

		const dispatch = this.props.dispatch;
		dispatch( fetchProductGet( this.props.auth.user, productId ))
  	}

	componentWillMount( ){
		if( !this.props.productId ) return false
		this.getProduct( this.props.productId )		
	}

	componentWillReceiveProps( newProps ){
		if( !newProps.productId ) return false
		
		const productId =	newProps.productId

		if( productId !== this.props.productId){
			this.getProduct( productId  )
		}
	}
	
	showInvoice( src ){
		this.setState({
			zoomableSrc: src,
		})
	} 
	
	render(){
		if( !this.props.product || !this.props.product[this.props.productId]) return false
		const product =	this.props.product[this.props.productId]
	    return (
	    	<div className="row">
		    	<div className="col-7 invoice-col">
		    		<div className="card" style={{marginTop: '24px',marginBottom: '12px'}}>
						<div className="card-footer">
							<ProductAdd />
						</div>
					</div>
					<div className="card">
						<div className="card-header">
							{product.name} 
							<div className="float-right">
								<ProductDelete id={product.id} />
							</div>
						</div>
						<div className="card-body">	
							<ProductEditForm product={product} auth={this.props.auth} onSaveField={(user,id,name,value) => this.props.onSaveField(user,id,name,value)}/>
						</div>
					</div>
					{product.bill_list.map( (billId) => {
						return (
						<InvoiceSummaryContainer
							key={billId}
							billId={billId}
							productId={product.id}
							onShowInvoice={(src) => this.showInvoice(src)}/>
						) 
					})}
				</div>
				<div className="col-5 img-col" style={{padding: 0,margin: 0}}>
					<InvoiceZoomable 
						width="400"
						height="900"
						src={this.state.zoomableSrc}
						/>
				</div>
			</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
		product: state.product.product,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
		onSaveField: (user,id,name,value) => dispatch( fetchProductSaveField(user,id,name,value) ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);  