import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import Page2Col from '../../components/Page2Col';

import StatsMenu from './stats/StatsMenu';

import ChartMoneyDayCompensate from './stats/ChartMoneyDayCompensate';
import ChartMoneyDay from './stats/ChartMoneyDay';
import ChartBudgetControl from './stats/ChartBudgetControl';
import ChartShopType from './stats/ChartShopType';
import ChartShopName from './stats/ChartShopName';
import ChartSunBurst from './stats/ChartSunBurst';

import {shopTypeList} from '../../constants/constants';
var moment = require("moment");


class StatsContainer extends Component { 
	constructor (props) { 		
		super(props);
				
		var momentFrom = moment().subtract(3, 'months')
		var momentTo =	moment()
		let showShopType = []
		shopTypeList.map( (st) => {
			showShopType[st.key] =	{
				key: st.key,
				label: st.label,
				checked: (st.key === "house_expense" || st.key === "travel")?0:1,
			}
			return true
		})
		this.state = {
			filter: {
				from:	momentFrom.format("YYYY-MM-DD"),
				to:	momentTo.format("YYYY-MM-DD"),
				showShopType:	showShopType,
			},
		}

		this.handleChange = this.handleChange.bind(this);		
		this.handleShopTypeChange = this.handleShopTypeChange.bind(this);		

	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		let newState = "";
		if( name === "to" || name === "from "){
			newState = update( this.state, {
				filter: { [name]: {$set: moment(value).format("YYYY-MM-DD")} },
			});	
		}else{
			newState = update( this.state, {
				filter: { [name]: {$set: value} },
			});
		}

		this.setState( newState );
	}


	handleShopTypeChange(event) {
		const target = event.target;
	    
	    const value = target.checked;
		const name = target.name;		
		let newState = update( this.state, {
			filter: { showShopType: {
				[name]: {
					checked: {$set: value} },
				},
			},
		});			

		this.setState( newState );
	}

	render(){
		const {billList,shopList,countryList} = this.props
	   	if( !billList 
	   		|| !shopList || !countryList ) return false

    	const menu = <StatsMenu 
    		filter={this.state.filter}
    		handleChange={this.handleChange}
    		handleShopTypeChange={this.handleShopTypeChange} />
    		
		const main = <div>
			<div className="card" style={{margin: '24px'}}>
				<ChartMoneyDayCompensate 
					billList={this.props.billList} 
					shopList={this.props.shopList}
					filter={this.state.filter} />
			</div>
			
			<div className="card" style={{margin: '24px'}}>
				<ChartMoneyDay
					billList={this.props.billList} 
					shopList={this.props.shopList}
					filter={this.state.filter} />	
			</div>

			<div className="card" style={{margin: '24px'}}>
				<ChartBudgetControl 
					billList={this.props.billList}
					shopList={this.props.shopList}
					filter={this.state.filter} />
			</div>
			<div className="row" style={{margin: '24px'}}>
				<div className="card col-12" >
					<ChartSunBurst 
						billList={this.props.billList}
						shopList={this.props.shopList}
						countryList={this.props.countryList}
						filter={this.state.filter} />
				</div>
			</div>
			<div className="row" style={{margin: '24px'}}>
				<div className="card col-6" >
					<ChartShopType 
						billList={this.props.billList}
						shopList={this.props.shopList}
						filter={this.state.filter} />
				</div>
				<div className="card col-6">
					<ChartShopName 
						billList={this.props.billList}
						shopList={this.props.shopList}
						filter={this.state.filter} />
				</div>
			</div>
				
				

		</div>

		return <Page2Col side={menu} main={main} /> 	
	}
}
	
					
const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
    	billList: state.bill.list,
    	shopList: state.shop.list,
    	countryList: state.country.list,		
	};
}
 

export default connect(mapStateToProps)(StatsContainer);  