import React, { Component } from 'react';

import Axes from '../../chart/Axes'
import Lines from '../../chart/Lines'
import IndicatorLine from '../../chart/IndicatorLine'

import ResponsiveWrapper from '../../chart/ResponsiveWrapper'

import { scaleTime, scaleLinear } from "d3-scale"
import { max } from "d3-array"
import "d3-transition"

import {shopTypeList} from '../../../constants';

import {getBillTotal,isBillFilter,isEmpty} from '../../../functions';

var moment = require("moment");

//import './ChartDays.css'

class ChartBudgetControl extends Component {
  
	render() {
		const {billList, shopList,filter} = this.props
		let columns = {}

		const label = shopTypeList.map( st => {
			return {
				key: st.key,
				label: st.label,
				color: st.color,
			}
		})

		Object.keys( billList ).map(( bill_id ) => {
			let bill = billList[bill_id];
			let shop = shopList[bill.shop_id];        
		  
			if( isBillFilter( bill ,shop, filter ) ){

				let som = getBillTotal( bill, shop )
				var d = moment(bill.date);
					 
				const key = d.format("YYYYMMDD");
				if( isEmpty( columns[key]) ){
					 columns[key] = {};
					 columns[key].date = d;
					 columns[key].money = 0;
				}
				columns[key].money += som;
		  	}

		  return true
		});
		
		var total = 0;
    	var day = 0;

    	var data = []
  		var curDate = moment(filter.from)
  		while( curDate.isBetween( moment(filter.from) , moment(filter.to),null,"[]")){
  			const key = curDate.format("YYYYMMDD")
  			const col = columns[key]

  			if(columns[key]){
  				total += parseFloat(col.money);
  			}			
			day += 1;

  			let colDay = {
				date:	curDate.format("YYYY-MM-DD"),
				money: 	parseFloat(total/day)
			}

 			data.push(colDay)
  			
  			curDate.add(1,'days')
  		}


		const margins = { top: 12, right: 12, bottom: 48, left: 48 }
		const svgDimensions = {
			width: Math.max(this.props.parentWidth, 300),
			height: 300
		}

		const maxValue = max( data.map( c => c.money ) ) + 20

		const xScale = scaleTime()
			.domain([moment(filter.from), moment(filter.to)])
			.range([margins.left, svgDimensions.width - margins.right])

		const yScale = scaleLinear()
			.domain([0,maxValue])
			.range([svgDimensions.height - margins.bottom, margins.top])

		return (
			<svg width={svgDimensions.width} height={svgDimensions.height}>
				<Axes
					scales={{ xScale, yScale }}
					margins={margins}
					svgDimensions={svgDimensions}
				/>

				<IndicatorLine 
        			score={yScale(1681.50)} 
        			color="#FF0000"
        			svgDimensions={svgDimensions}
        			margins={margins} />
        		<IndicatorLine 
        			score={yScale(1681.50 - 650)} 
        			color="#00FF00"
        			svgDimensions={svgDimensions}
        			margins={margins} />
        			
				<Lines
					scales={{ xScale, yScale }}
					margins={margins}
					label={label}
					data={data}
					maxValue={maxValue}
					svgDimensions={svgDimensions}
				/>
			</svg>
		)
	}
}

export default ResponsiveWrapper(ChartBudgetControl)