import {shopService, productService} from '../services';
import { alertActions } from './';

export const REQUEST_PATTERN_SHOP_SAVE_FIELD = 'REQUEST_PATTERN_SHOP_SAVE_FIELD' 
function requestPatternShopSaveField( ) {
  return {
    type: REQUEST_PATTERN_SHOP_SAVE_FIELD,
  }
}

export const RECEIVE_PATTERN_SHOP_SAVE_FIELD = 'RECEIVE_PATTERN_SHOP_SAVE_FIELD'
function receivePatternShopSaveField(pattern) {
  return {
    type: RECEIVE_PATTERN_SHOP_SAVE_FIELD,
    pattern: pattern,
  }
}

export function fetchPatternShopSaveField( user, id, name, value ) {
  return function (dispatch) {
    dispatch(requestPatternShopSaveField())

    return shopService.patternSaveField( user, id, name, value )
      .then(
        elem => { 
          dispatch( receivePatternShopSaveField(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}


export const REQUEST_PATTERN_SHOP_ADD = 'REQUEST_PATTERN_SHOP_ADD'
function requestPatternShopAdd() {
  return {
    type: REQUEST_PATTERN_SHOP_ADD,    
  }
}

export const RECEIVE_PATTERN_SHOP_ADD = 'RECEIVE_PATTERN_SHOP_ADD'
function receivePatternShopAdd( pattern ) {
  return {
    type: RECEIVE_PATTERN_SHOP_ADD,
    pattern: pattern,
  }
}

export function fetchPatternShopAdd( user, relId, pattern ) {
 
  return function (dispatch) {
    dispatch(requestPatternShopAdd())

    return shopService.patternAdd( user, relId, pattern )
      .then(
        elem => { 
          dispatch( receivePatternShopAdd(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PATTERN_SHOP_DELETE = 'REQUEST_PATTERN_SHOP_DELETE'
function requestPatternShopDelete() {
  return {
    type: REQUEST_PATTERN_SHOP_DELETE,    
  }
}

export const RECEIVE_PATTERN_SHOP_DELETE = 'RECEIVE_PATTERN_SHOP_DELETE'
function receivePatternShopDelete( pattern ) {
  return {
    type: RECEIVE_PATTERN_SHOP_DELETE,
    pattern: pattern,
  }
}

export function fetchPatternShopDelete( user, id ) {
 
  return function (dispatch) {
    dispatch(requestPatternShopDelete())

    return shopService.patternDelete( user, id )
      .then(
        elem => { 
          dispatch( receivePatternShopDelete(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PATTERN_PRODUCT_SAVE_FIELD = 'REQUEST_PATTERN_PRODUCT_SAVE_FIELD' 
function requestPatternProductSaveField( ) {
  return {
    type: REQUEST_PATTERN_PRODUCT_SAVE_FIELD,
  }
}

export const RECEIVE_PATTERN_PRODUCT_SAVE_FIELD = 'RECEIVE_PATTERN_PRODUCT_SAVE_FIELD'
function receivePatternProductSaveField(pattern) {
  return {
    type: RECEIVE_PATTERN_PRODUCT_SAVE_FIELD,
    pattern: pattern,
  }
}

export function fetchPatternProductSaveField( user, id, name, value ) {
  return function (dispatch) {
    dispatch(requestPatternProductSaveField())

    return productService.patternSaveField( user, id, name, value )
      .then(
        elem => { 
          dispatch( receivePatternProductSaveField(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}


export const REQUEST_PATTERN_PRODUCT_ADD = 'REQUEST_PATTERN_PRODUCT_ADD'
function requestPatternProductAdd() {
  return {
    type: REQUEST_PATTERN_PRODUCT_ADD,    
  }
}

export const RECEIVE_PATTERN_PRODUCT_ADD = 'RECEIVE_PATTERN_PRODUCT_ADD'
function receivePatternProductAdd( pattern ) {
  return {
    type: RECEIVE_PATTERN_PRODUCT_ADD,
    pattern: pattern,
  }
}

export function fetchPatternProductAdd( user, relId, pattern ) {
 
  return function (dispatch) {
    dispatch(requestPatternProductAdd())

    return productService.patternAdd( user, relId, pattern )
      .then(
        elem => { 
          dispatch( receivePatternProductAdd(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PATTERN_PRODUCT_DELETE = 'REQUEST_PATTERN_PRODUCT_DELETE'
function requestPatternProductDelete() {
  return {
    type: REQUEST_PATTERN_PRODUCT_DELETE,    
  }
}

export const RECEIVE_PATTERN_PRODUCT_DELETE = 'RECEIVE_PATTERN_PRODUCT_DELETE'
function receivePatternProductDelete( pattern ) {
  return {
    type: RECEIVE_PATTERN_PRODUCT_DELETE,
    pattern: pattern,
  }
}

export function fetchPatternProductDelete( user, id ) {
 
  return function (dispatch) {
    dispatch(requestPatternProductDelete())

    return productService.patternDelete( user, id )
      .then(
        elem => { 
          dispatch( receivePatternProductDelete(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}


/*
export const REQUEST_PATTERN_SAVE_FIELD = 'REQUEST_PATTERN_SAVE_FIELD'
 
function requestPatternSaveField( id ) {
  return {
    type: REQUEST_PATTERN_SAVE_FIELD,
    id
  }
}

export const RECEIVE_PATTERN_SAVE_FIELD = 'RECEIVE_PATTERN_SAVE_FIELD'
 
function receivePatternSaveField(json) {
  return {
    type: RECEIVE_PATTERN_SAVE_FIELD,
    pattern: json.elem,
  }
}

export function fetchPatternSaveField( id, name, value ) {
  return function (dispatch) {
    dispatch(requestPatternSaveField())

    return fetch( API_URL +'product/pattern_save_field',
        {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          name: name,
          value: value,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then (json =>
        dispatch(receivePatternSaveField( json ))
      )
  }
}

export const REQUEST_PATTERN_ADD = 'REQUEST_PATTERN_ADD'

function requestPatternAdd() {
  return {
    type: REQUEST_PATTERN_ADD,    
  }
}

export const RECEIVE_PATTERN_ADD = 'RECEIVE_PATTERN_ADD'
 
function receivePatternAdd( json ) {
  return {
    type: RECEIVE_PATTERN_ADD,
    list: json.elem,
  }
}

export function fetchPatternAdd( pattern, productDetId) {
 
  return function (dispatch) {
    dispatch(requestPatternAdd())
    return fetch( API_URL +'product/pattern_add/',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pattern: pattern,
          product_det_id: productDetId,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then( json =>
        dispatch( receivePatternAdd(json) )
      )
  }
}

export const REQUEST_PATTERN_DELETE = 'REQUEST_PATTERN_DELETE'

function requestPatternDelete() {
  return {
    type: REQUEST_PATTERN_DELETE,    
  }
}

export const RECEIVE_PATTERN_DELETE = 'RECEIVE_PATTERN_DELETE'
 
function receivePatternDelete( json ) {
  return {
    type: RECEIVE_PATTERN_DELETE,
    id: json.elem_id,
  }
}

export function fetchPatternDelete( id ) {
 
  return function (dispatch) {
    dispatch(requestPatternDelete())

    return fetch( API_URL +'product/pattern_delete',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
        })
      })
      .then(
        response => response.json(),
        error => console.log('An error occurred.', error)
      )
      .then(json =>
        dispatch( receivePatternDelete( json ) )
      )
  }
}*/