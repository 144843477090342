import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import BillAddContainer from '../../../components/bill/billAddContainer/BillAddContainer';

import Slide from '@material-ui/core/Slide';

const styles = theme => ({
	
})

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class BillDialogAddContainer extends Component {	

	render() {		
		const {open,handleClose} = this.props;
		return <Dialog open={open}
					onClose={handleClose}
					TransitionComponent={Transition}>
			<DialogContent>
				<BillAddContainer/>
			</DialogContent>
		</Dialog>
	}
}

export default withStyles(styles)(BillDialogAddContainer)