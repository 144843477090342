import { connect } from 'react-redux';
import { authenticationActions } from '../../actions';
import Login from './login/Login'

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn,
        onLogout: state.onLogout,
        onLogin: state.onLogin,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onLogout: () => {
			dispatch( authenticationActions.logout() )
		},
		onLogin: (username, password) => {
			dispatch( authenticationActions.login(username, password) )
		},
	}
}

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login)


export default LoginContainer;