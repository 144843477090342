import React from 'react';
import {API_URL} from '../constants';

export function fetchApi( url, post = {}){
    const apiUrl = API_URL + url
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(post)
    };

    return fetch(apiUrl, requestOptions)
        .then(
            response => response.json(),
            error => console.log('An error occurred.', error)
        )
        .then( json => {
            if (!json){
                return Promise.reject(json);                
            }else if(!json.success) { 
                let errorList =    (<div>
                    <strong>Error:</strong>
                        <ul>{
                        json.error_list.map( (error,i) => {
                            return (<li key={"errorList"+ i}>{error}</li>)
                        })
                    }</ul>
                    <i>{json.debug}</i>
                </div>)
                return Promise.reject(errorList);
            }

            return json.elem;
        })
}
