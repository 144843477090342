import {fetchApi} from '../helpers';

export const cityService = {
    loadList,    
    add,
    saveField,
    _delete,  
};

function loadList( user ) {
    return fetchApi('city/load', {user: user})   
}

function add( user, city, shop_id ) {
    return fetchApi('city/add', {
    	user: user,
    	city: city,
        shop_id: shop_id,
    })   
}

function saveField( user, id, name, value) {
    return fetchApi('city/save_field/', {user: user, id: id, name: name, value: value })   
}

function _delete( user, id) {
    return fetchApi('city/delete/', { user: user, id: id })   
}