import React, {Component} from 'react';

import update from 'immutability-helper';

import Select from 'react-select';
//import 'react-select/dist/react-select.css';

class ProductDetAddForm extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			product: this.emptyProduct(),			
		}
		this.handleChange = this.handleChange.bind(this);
	}

	emptyProduct(){
		let emptyProduct = { 
			product_id: "",
			position: "",
			price: "",
			price_unit: "",
			num: "",
			weight: "",
		}
		return emptyProduct;
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			product: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSelectChange = (selectedOption) => {			
		if( selectedOption ){
			let newState = update( this.state, {
				product: {
					product_id: {$set: selectedOption.value},
				},
			});
			this.setState( newState );
		}
  	}

	render(){	
		const product =	this.state.product;
		const productList =	this.props.productList;
		if( !product || !productList ) return false
		const productOptions = Object.keys(productList).map( (i) => {
			return (
				{ value: productList[i].id, label: productList[i].name }
			);
		});

		return (
		<div>
			<div className="row">	
		  		<div className="col-6">
  					<Select
						name="product_id"
						value={product.product_id}
						options={productOptions}
						onChange={this.handleSelectChange.bind( this )}
						style={{width: "100%"}}
					/>
  				</div>
  				<div className="col-3">
  					<input type="text" className="form-control" placeholder="Price"
					 	name="price" value={product.price} 
					 	onChange={this.handleChange} />
  				</div>	
  				<div className="col-3">
  					<input className="btn btn-primary" type="button" value="Add" 
  						style={{width: "100%",}}
  						onClick={() => this.props.onAdd(product,this.props.billId)} />
  				</div>	  				
  			</div>
  			<div className="row">
  				<div className="col-3">
  					<input type="text" className="form-control" placeholder="Num"
					 	name="num" value={product.num} 
					 	onChange={this.handleChange} />	
  				</div>
  				<div className="col-3">
  					<input type="text" className="form-control" placeholder="Weight"
					 	name="weight" value={product.weight} 
					 	onChange={this.handleChange} />	
  				</div>

  				<div className="col-3">
  					<input type="text" className="form-control" placeholder="Price Unit"
					 	name="price_unit" value={product.price_unit}
					 	onChange={this.handleChange} />	
  				</div>
  			</div>
  		</div>
		);
	}
}

export default ProductDetAddForm;