import React, { Component } from 'react';
import LabeledArc from './LabeledArc';
import { pie as d3Pie} from 'd3-shape';

 
class Piechart extends Component {
    constructor() {
        super();
 
        this.pie = d3Pie().value((d) => d.money)
    }
 
    arcGenerator(d, i) {
        return (
            <LabeledArc key={`arc-${i}`}
                data={d}
                innerRadius={this.props.innerRadius}
                outerRadius={this.props.outerRadius}
                color={d.data.color} />
        );
    }
 
    render() {
        let pie = this.pie(this.props.data),
            translate = `translate(${this.props.x}, ${this.props.y})`;
 
        return (
            <g transform={translate}>
                {pie.map((d, i) => this.arcGenerator(d, i))}
            </g>
        )
    }
}
 
export default Piechart;