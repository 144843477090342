export function db2region( db_region ){
	const region = {
		x: parseFloat(db_region.x),
		y: parseFloat(db_region.y),
		width: parseFloat(db_region.width),
		height: parseFloat(db_region.height),
		data: {
			dataType: db_region.type,
		},
	};
	return region;
}