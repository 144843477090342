export const regionsType = [
	{ key: 'shop_name', label: 'Shop Name', 'required': false, 'done': false },
	{ key: 'price_total', label: 'Price Total', 'required': true, 'done': false },
	{ key: 'product_list', label: 'Product List', 'required': false, 'done': false },
	{ key: 'product_amount', label: 'Product Amount', 'required': false, 'done': false },
	{ key: 'product_price', label: 'Product Price', 'required': false, 'done': false },
	{ key: 'date_time', label: 'Date and Time', 'required': false, 'done': false },
];

export const shopTypeList =	[
	{ 'key': 'atm', 'label' : 'ATM','icon': 'money-bill-alt','color': '#90EE90'},
	{ 'key': 'bakery', 'label' : 'Bakery', 'icon': 'birthday-cake','color': '#FFFACD'},
	{ 'key': 'bar', 'label' : 'Bar', 'icon': 'beer','color': '#DC143C'},
	{ 'key': 'cafe', 'label' : 'Cafe', 'icon': 'coffee','color': '#D2691E'},
	{ 'key': 'clothes', 'label' : 'Clothes','icon': 'tshirt','color': '#FFB6C1'},		
	{ 'key': 'entertainment', 'label' : 'Entertainment','icon': 'ticket-alt','color': '#FF8C00'},	
	{ 'key': 'farmacy', 'label' : 'Farmacy','icon': 'pills','color': '#4B0082'},		
	{ 'key': 'gift', 'label' : 'Gifts','icon': 'gift','color': '#EE1289'},	
	{ 'key': 'hotel', 'label' : 'Hotel', 'icon': 'h-square','color': '#4169E1'},	
	{ 'key': 'house_expense', 'label' : 'House Expenses', 'icon': 'home','color': '#808000'},	
	{ 'key': 'mountain', 'label' : 'Mountain','icon': 'binoculars','color': '#00FF7F'},		
	{ 'key': 'restaurant', 'label' : 'Restaurant', 'icon': 'utensils','color': '#FFDAB9'},
	{ 'key': 'shop', 'label' : 'Shop','icon': 'shopping-bag','color': '#FF69B4'},
	{ 'key': 'sport', 'label' : 'Sport','icon': 'bicycle','color': '#FFD700'},	
	{ 'key': 'supermarket', 'label' : 'Super Market', 'icon': 'shopping-cart','color': '#FF6347'},
	{ 'key': 'transportation', 'label' : 'Transportation','icon': 'train','color': '#808080'},	
	{ 'key': 'travel', 'label' : 'Travel','icon': 'plane','color': '#4169E1'},	
];


export const productTypeList =	[
	{ 'key': 'koshka', 'label' : 'Koshka','icon': 'paw'},
	{ 'key': 'bag', 'label' : 'Plastic Bag', 'icon': 'shopping-bag'},
	{ 'key': 'prepared_food', 'label' : 'Prepared Food', 'icon': 'utensils'},
	{ 'key': 'tea', 'label' : 'Tea/Coffee', 'icon': 'coffee'},
	{ 'key': 'sweets', 'label' : 'Sweets','icon': 'birthday-cake'},	
	{ 'key': 'veg_fruits', 'label' : 'Vegetables & Fruits','icon': 'leaf'},
	{ 'key': 'dairy', 'label' : 'Dairy','icon': ''},	
	{ 'key': 'house', 'label' : 'House','icon': 'home'},	
];

let apiUrl;
let domainUrl;
let domainAssets;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  apiUrl = 'http://budget.local.com/api.php/';
  domainUrl = 'http://budget.local.com/';
  domainAssets = 'http://budget.hectormarti.com/';

} else {
  apiUrl = 'http://budget.hectormarti.com/api.php/';
  domainUrl = 'http://budget.hectormarti.com/';
  domainAssets = 'http://budget.hectormarti.com/';  
}


export const API_URL =	apiUrl;
export const DOMAIN_URL =	domainUrl;
export const DOMAIN_ASSETS =	domainAssets;


