import {fetchApi} from '../helpers';

export const productService = {
    load,
	get,
    add,
    saveField,
    _delete,
    productBillLoad,
    productBillDelete,
    productBillSaveField,
    patternAdd,
    patternSaveField,
    patternDelete, 
};

function load( user ) {
    return fetchApi('product/load', {
        user: user,
    })   
}

function get( user, id ) {
    return fetchApi('product/get', {
    	user: user,
    	id: id,
    })   
}
function add( user, product ) {
    return fetchApi('product/add/', {user: user, product: product })   
}
function saveField( user, id, name, value) {
    return fetchApi('product/save_field/', {user: user, id: id, name: name, value: value })   
}
function _delete( user, id) {
    return fetchApi('product/delete/', { user: user, id: id })   
}

function productBillLoad( user, billId ) {
    return fetchApi('product/bill_load_by_bill_id/', {user: user, id: billId })   
}
function productBillDelete( user, id ) {
    return fetchApi('product/bill_delete/', {user: user, id: id })   
}
function productBillSaveField( user, id, name, value) {
    return fetchApi('product/bill_save_field/', {user: user, id: id, name: name, value: value })   
}

function patternAdd( user, relId, pattern) {
    pattern['product_det_id'] =    relId
    return fetchApi('product/pattern_add/', {user: user, pattern: pattern })   
}

function patternSaveField( user, id, name, value) {
    return fetchApi('product/pattern_save_field/', {user: user, id: id, name: name, value: value })   
}

function patternDelete( user, id) {
    return fetchApi('product/pattern_delete/', { user: user, id: id })   
}