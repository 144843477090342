import React, { Component } from 'react';

import Piechart from '../../chart/Piechart'


import ResponsiveWrapper from '../../chart/ResponsiveWrapper'

import "d3-transition"

import {shopTypeList} from '../../../constants';

import {getBillTotal,isBillFilter,isEmpty} from '../../../functions';


//import './ChartDays.css'

class ChartShopType extends Component {
  
  	render() {
  		const {billList, shopList,filter} = this.props
  		let columns = {}

  		Object.keys(billList).map(i => {
  			const bill = billList[i]
  			const shop = shopList[bill.shop_id]

         let som = getBillTotal( bill, shop )

  			if( isBillFilter( bill ,shop, filter ) ){  
  				columns[shop.id] = columns[shop.id] || {}

            if( isEmpty( columns[shop.id]) ){
            	const label = shopTypeList.filter( st => {
	         		if(shop.type === st.key){
	         			return true
	         		}
	         		return false
	         	})
	         	columns[shop.id].color = label[0].color
	         	columns[shop.id].label = shop.name
            	//columns[shop.id].name = label[0].name

            	columns[shop.id].money = 0
            }

	         columns[shop.id].money += som
         }

         return true
  		})
  		
  		var dataPie = Object.keys(columns).map(i =>{
  			let col = columns[i]
  			return ({
  				label: col.label,
  				color: col.color,
  				money: col.money,
  			})
  		})

		const svgDimensions = {
   		width: Math.max(this.props.parentWidth, 300),
   		height: 300
      }
		return (
			<svg width={svgDimensions.width} height={svgDimensions.height}>
				<Piechart x={svgDimensions.width/2} y={svgDimensions.height/2} outerRadius={svgDimensions.height/2} innerRadius={svgDimensions.height/4}
          		data={dataPie} />
         </svg>
		)
  	}
}

export default ResponsiveWrapper(ChartShopType)