import { userService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const authenticationActions = {
    login,
    logout,
};

export const AUTHENTICATION_LOGIN_REQUEST = 'AUTHENTICATION_LOGIN_REQUEST'
export const AUTHENTICATION_LOGIN_SUCCESS = 'AUTHENTICATION_LOGIN_SUCCESS'
export const AUTHENTICATION_LOGIN_FAILURE = 'AUTHENTICATION_LOGIN_FAILURE'

export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT'

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: AUTHENTICATION_LOGIN_REQUEST, user } }
    function success(user) { return { type: AUTHENTICATION_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: AUTHENTICATION_LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: AUTHENTICATION_LOGOUT };
}