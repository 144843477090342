import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import { interpolateLab } from 'd3-interpolate'
import { select as d3Select } from 'd3-selection'

import './Bars.css'

export default class Bars extends Component {
  constructor(props) {
    super(props)

    this.colorScale = scaleLinear()
      .domain([0, this.props.maxValue])
      .range(['#F3E5F5', '#00FF00'])
      .interpolate(interpolateLab)
  }

  render() {
    const { scales, data, colors,label } = this.props
    const { xScale } = scales

    var div = d3Select("body").append("div") 
      .attr("class", "tooltip")       
      .style("opacity", 0);

    let col = colors
    if( !colors ){
      col = ['#0000FF', '#FF0000']
    }

    let s = 0
    const bars = ( 
      data.map( series => {  
        s++
        return series.map( datum => {   
            const labelType = ( label )?label[s-1].label:""
            const labelValue = ( label && datum.data )?datum.data[label[s-1].key]:""
            const labelColor = ( label )?label[s-1].color:""

            if( !datum[0] ) datum[0] = 0 
            if( !datum[1] ) datum[1] = 0
            
            return <rect
              key={datum.data.label}
              x={scales.xScale(datum.data.label)}
              y={scales.yScale(datum[1])}
              height={ scales.yScale(datum[0]) - scales.yScale(datum[1]) }
              width={xScale.bandwidth()}
              fill={col[(s-1)]}
              onMouseOver={ d => { 
                if( labelValue ){
                  div.transition()    
                    .duration(200)    
                    .style("opacity", 1)
                    .style("border-color", labelColor)

                  div.html( "<strong>" + labelType +"</strong>: "+ parseInt(labelValue,10) +" €")  
                    .style("left", (d.pageX) + "px")   
                    .style("top", (d.pageY - 28) + "px"); 
                } 
              }}          
              onMouseOut={ d => {   
                div.transition()    
                  .duration(500)    
                  .style("opacity", 0)
              }}
            />
        })        
      })
    )

    return (
      <g>{bars}</g>
    )
  }
}