import React from 'react';
import PropTypes from 'prop-types'


import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';

import { withStyles } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';


import BillLine from './BillLine';

var moment = require("moment");

const styles = theme => ({
	price:{
		textAlign: "right",
	}
})

const BillDay = ({ date, list, total, cityList,classes}) => (
	<List key={date}>		
		<ListSubheader disableSticky={true}>{moment(date).calendar(null, {
	    sameDay: '[Today]',
	    nextDay: '[Tomorrow]',
	    nextWeek: 'dddd',
	    lastDay: '[Yesterday]',
	    lastWeek: 'dddd',
	    sameElse: 'DD/MM/YYYY'
	})}</ListSubheader>	
		{list.map( (bill) => {
			return ( <BillLine key={bill.bill.bill_id} bill={bill.bill} shop={bill.shop} /> )
		})}
		<Divider />
		<ListItem >
			<ListItemText secondary={cityList.map( c => {
					if( c ) return <span key={c.id} style={{paddingRight: "8px"}}>{c.name}</span>
					return false
				})} />
			<ListItemText secondary={<small>{total.toFixed(2) +" €"}</small>} className={classes.price} />
		</ListItem>			
	</List>
);

BillDay.propTypes = {
  date: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired
}

export default withStyles(styles)(BillDay)