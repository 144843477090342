import React, {Component} from 'react';
import Creatable from 'react-select/creatable';

import {shopTypeList} from '../../../../constants/constants';

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	invoiceText: {
		padding: "24px",
	}
})


class ShopEdit extends Component { 

	render() {		
		const {shop,countryList,cityList,handleChange,handleBlur,
			handleCreate,handleCreateableChange, handleInputChange,
			cityIsLoading, cityName} = this.props
		if( !shop ) return false;
		if( !countryList ) return false;
		if( !cityList ) return false;

		return <div class="row" style={{paddingBottom: "48px"}}>
			<div class="col-6">
				<div className="form-group">
			    	<label>Name</label>
			    	<input type="text" className="form-control" placeholder="Shop Name"
			    		name="name" value={shop.name} 
			    		onChange={handleChange}
			    		onBlur={handleBlur}
			    		 />
			  	</div> 
			  	<div className="form-group">
			    	<label>Type</label>
			    	<select className="form-control" name="type" 
			    		value={shop.type} 
			    		onChange={handleChange}
			    		onBlur={handleBlur} >
			    		<option value="">-</option>
			    		{shopTypeList.map( (shopType) => (
							<option key={shopType.key} 
							value={shopType.key}>{shopType.label}</option>
							))
			    		}
					</select>					    	
			  	</div> 
			</div>
			<div class="col-6">
			  	<div className="form-group">
			    	<label>Country</label>
			    	<select className="form-control" name="country_id" 
			    		value={shop.country_id} 
			    		onChange={handleChange}
			    		onBlur={handleBlur} >
			    		<option value="">-</option>
			    		{countryList.map( (country) => (
							<option key={country.id} 
							value={country.id}>{country.name}</option>
							))
			    		} 								
					</select>					    	
			  	</div>
			  	<div className="form-group">
			    	<label>City</label>
			    	<Creatable
        				isClearable
        				isDisabled={cityIsLoading}
        				isLoading={cityIsLoading}
        				onInputChange={handleInputChange}
        				onChange={handleCreateableChange}
        				onCreateOption={handleCreate}
        				options={Object.keys(cityList).map( (i) => {
								return (
									{ value: cityList[i].id, label: cityList[i].name }
								);
							})}        				
        				defaultInputValue={cityName}
      				/>
			  	</div> 
			</div>
		</div>
	}
}

export default withStyles(styles)(ShopEdit)