import React, {Component} from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { billActions } from '../../actions'


class BillDeleteContainer extends Component {
	constructor (props) { 		
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}


	handleClick(event) {
		const {dispatch,auth,id} = this.props
		if (window.confirm('Are you sure you wish to delete this bill?')){
			dispatch( billActions._delete(auth.user, id) )
		}
	}

	render(){	
		if( !this.props.id ) return false

		return <button type="button"
			className="btn btn-outline-danger btn-sm" 
			onClick={this.handleClick}>
              <FontAwesomeIcon icon="trash-alt" size="sm"/>
      </button>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication, 
	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillDeleteContainer);