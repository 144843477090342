import React, { Component } from 'react';

import SunBurst from '../../chart/SunBurst'


import ResponsiveWrapper from '../../chart/ResponsiveWrapper'

import "d3-transition"

import {shopTypeList} from '../../../constants';
import {getConstant} from '../../../functions';

import {getBillTotal,isBillFilter} from '../../../functions';


class ChartSunBurst extends Component {
  
  	render() {
  		const {billList, shopList,countryList,filter} = this.props
      let csv = ""
  		Object.keys(billList).map(i => {
  			const bill = billList[i]
  			const shop = shopList[bill.shop_id]

        	let som = getBillTotal( bill, shop )
  			if( isBillFilter( bill ,shop, filter ) ){  
  				let countryFilter = countryList.filter(function(c){
  					if(shop.country_id === c.id){
  						return c
  					}
  					else{
  						return false
  					}
  				})
  				let country = countryFilter[0]

  				let shopType = getConstant(shopTypeList,shop.type) 
  				csv = csv + shopType.label +"-"+ country.name +"-"+ shop.name.replace("\n","") +"-"+ bill.date_dma +","+ som +"\n"
         }
         return true
  		})
  		
		/*const svgDimensions = {
   		width: Math.max(this.props.parentWidth, 300),
   		height: 300
      }*/
		return (
				<SunBurst csv={csv} countryList={countryList} />
		)
  	}
}

export default ResponsiveWrapper(ChartSunBurst)