import React, { Component } from 'react';
import { arc as d3Arc} from 'd3-shape';
import { select as d3Select } from 'd3-selection'

 
class Arc extends Component {
    constructor() {
        super();
 
        this.arc = d3Arc();
    }
    
    componentWillMount() {
        this.updateD3(this.props);
    }
 
    componentWillReceiveProps(newProps) {
        this.updateD3(newProps);
    }
 
    updateD3(newProps) {
        this.arc.innerRadius(newProps.innerRadius);
        this.arc.outerRadius(newProps.outerRadius);
    }

 
    render() {
        var div = d3Select("body").append("div") 
            .attr("class", "tooltip")       
            .style("opacity", 0);



        return (
            <path d={this.arc(this.props.data)}
                style={{fill: this.props.color}}
                onMouseOver={ d => { 
                  div.transition()    
                    .duration(200)    
                    .style("opacity", 1)
                    .style("border-color", this.props.color)

                  div.html( "<strong>" + this.props.data.data.label +"</strong>: "+ parseInt(this.props.data.data.money,10) +" c")  
                    .style("left", (d.pageX) + "px")   
                    .style("top", (d.pageY - 28) + "px"); 
                }}          
                onMouseOut={ d => {   
                    div.transition()    
                        .duration(500)    
                        .style("opacity", 0)
                }}
              ></path>
        );
    }
}

class LabeledArc extends Arc {
    render() {
        
 
        return (
            <g>
                {super.render()}
            </g>
        );

       /* 
       let [labelX, labelY] = this.arc.centroid(this.props.data),
            labelTranslate = `translate(${labelX}, ${labelY})`;
       return (
            <g>
                {super.render()}
                <text transform={labelTranslate}
                      textAnchor="middle">
                    {this.props.data.data.label}
                </text>
            </g>
        );*/
    }
}
 
export default LabeledArc;