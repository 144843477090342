import { connect } from 'react-redux'

import PatternForm from '../../components/pattern/PatternForm'

import { fetchPatternProductSaveField, fetchPatternProductAdd, fetchPatternProductDelete } from '../../actions/pattern'


const mapStateToProps = (state, ownProps) => {
	return {
		...state,
		...ownProps,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onAdd: (user, relId, pattern) => dispatch( fetchPatternProductAdd(user, relId, pattern) ),		
		onSaveField: (user, id,name,value) => dispatch( fetchPatternProductSaveField(user, id, name, value) ),
		onDelete: (user, id) => dispatch( fetchPatternProductDelete(user, id) )
	}
}
 
const PatternProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatternForm)


export default PatternProductContainer;