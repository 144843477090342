import React, {Component} from 'react';
import { connect } from 'react-redux'

import Menu from './menu/Menu'

class MenuContainer extends Component { 

	constructor(props) {
		super(props);

		this.state = {
		}
	}

	render(){
		return  (
            <Menu 
            	year={this.props.year}
            	handleYearChange={this.props.handleYearChange} />
		)
  }
}

const mapStateToProps = (state, ownProps) => {
  	return { }
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
