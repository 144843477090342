import React, {Component} from 'react';

import { connect } from 'react-redux'

import Page2Col from '../Page2Col';
import update from 'immutability-helper';

import BillDialogAddContainer from '../../components/bill/billDialogAddContainer/BillDialogAddContainer';

import BillMenuContainer from '../../components/bill/billMenuContainer/BillMenuContainer';
import Bill from '../../components/bill/billContainer/BillContainer';
import AlertContainer from '../../containers/AlertContainer';


import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';


import { fetchInvoiceGet } from '../../actions'

class BillPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            billId: "",
            openAddDialog : false,
        }
    }

    handleClickOpenAdd = () => {
        this.setState({ openAddDialog: true });
    };

    handleCloseAdd = () => {
        this.setState({ openAddDialog: false });
    };
    
    componentWillMount(){ 
        const billId =  this.props.match.params.billId
        if( !billId ) return false

        let newState = update( this.state, {
            billId: {$set: billId},
        });
        this.setState( newState );  

        const dispatch = this.props.dispatch;
        dispatch( fetchInvoiceGet( this.props.auth.user, billId ))
    }

    
    componentWillReceiveProps( nextProps ){
        const billId =  nextProps.match.params.billId
        if( !billId ) return false

        if( billId !== this.props.match.params.billId){
            const dispatch = this.props.dispatch;       
            dispatch( fetchInvoiceGet( this.props.auth.user, billId ))
        }

        let newState = update( this.state, {
            billId: {$set: billId},
        });
        this.setState( newState );        
    }


    render(){
        const side = <div>
            <Fab color="primary" aria-label="add" onClick={this.handleClickOpenAdd} 
                style={{
                    margin: "16px",
                    position: "fixed",
                    bottom: 0,
                    zIndex: 99,
                }}>
                <AddIcon />
            </Fab>
            <BillMenuContainer />
            <BillDialogAddContainer 
                open={this.state.openAddDialog} handleClose={this.handleCloseAdd} />
        </div>

        const main = <div>
            <AlertContainer />
            <Bill invoice={this.props.invoice} billId={this.state.billId}/>
        </div>

        return <Page2Col  
            side={side} 
            main={main}
            year={this.props.year}
            handleYearChange={this.props.handleYearChange}  />
        
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.authentication,
        invoice: state.bill.invoice,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillPage);
