import React, { Component } from 'react'
import { line } from 'd3-shape'

import './IndicatorLine.css'

export default class IndicatorLine extends Component {
 
  render() {
  		const {score,svgDimensions,margins,color} = this.props
    	return <line
         key={"line"+ score}
         x1={margins.left}
         y1={score}
         x2={svgDimensions.width}
         y2={score}
         fill={color}
         className="zeroline"
			/>
	}

}