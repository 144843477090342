import React from 'react';
import TopMenu from '../components/TopMenu';
import AlertContainer from '../containers/AlertContainer';

const PageGeneral = (props) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-3 left-menu bg-light border-right">
                <TopMenu />        
                {props.menu}
            </div>
            <div className="col-9 main">
                <AlertContainer />
                {props.main}
            </div>
        </div>	
    </div>
);

export default PageGeneral;