import {shopService} from '../services';
import { alertActions } from './';

export const shopActions = {
    load,
    add,    
    _delete,
}

export const SHOP_LOAD_REQUEST = 'SHOP_LOAD_REQUEST'
export const SHOP_LOAD_SUCCESS = 'SHOP_LOAD_SUCCESS'
export const SHOP_LOAD_FAILURE = 'SHOP_LOAD_FAILURE'

function load( user ) {
    return dispatch => {
        dispatch(request( ));   
        shopService.loadList( user )
            .then(
                response => { 
                  dispatch(success(response));
                  //dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: SHOP_LOAD_REQUEST } 
    }
    function success(rows) { return { type: SHOP_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: SHOP_LOAD_FAILURE, error } }
}

export const SHOP_ADD_REQUEST = 'SHOP_ADD_REQUEST'
export const SHOP_ADD_SUCCESS = 'SHOP_ADD_SUCCESS'
export const SHOP_ADD_FAILURE = 'SHOP_ADD_FAILURE'

function add( user, shop ) {    
    return dispatch => {
        dispatch(request( shop ));   
        shopService.add( user, shop )
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( shop ) { 
        return { type: SHOP_ADD_REQUEST, shop } 
    }
    function success(rows) { return { type: SHOP_ADD_SUCCESS, rows } }
    function failure(error) { return { type: SHOP_ADD_FAILURE, error } }
}

export const REQUEST_SHOP_GET = 'REQUEST_SHOP_GET'
function requestShopGet( ) {
  return {
    type: REQUEST_SHOP_GET,
  }
}

export const RECEIVE_SHOP_GET = 'RECEIVE_SHOP_GET'
function receiveShopGet(shop) {
  return {
    type: RECEIVE_SHOP_GET,
    shop: shop,
  }
}

export function fetchShopGet( user, shopId ) {
  return function (dispatch) {
    dispatch(requestShopGet())

    return shopService.get( user, shopId )
      .then(
        elem => { 
          dispatch( receiveShopGet(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_SHOP_SAVE_FIELD = 'REQUEST_SHOP_SAVE_FIELD' 
function requestShopSaveField( ) {
  return {
    type: REQUEST_SHOP_SAVE_FIELD,
  }
}

export const RECEIVE_SHOP_SAVE_FIELD = 'RECEIVE_SHOP_SAVE_FIELD'
function receiveShopSaveField(shop) {
  return {
    type: RECEIVE_SHOP_SAVE_FIELD,
    shop: shop,
  }
}

export function fetchShopSaveField( user, id, name, value ) {
  return function (dispatch) {
    dispatch(requestShopSaveField())

    return shopService.saveField( user, id, name, value )
      .then(
        elem => { 
          dispatch( receiveShopSaveField(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const SHOP_DELETE_REQUEST = 'SHOP_DELETE_REQUEST'
export const SHOP_DELETE_SUCCESS = 'SHOP_DELETE_SUCCESS'
export const SHOP_DELETE_FAILURE = 'SHOP_DELETE_FAILURE'

function _delete( user, id ) { 
    return dispatch => {
        dispatch(request( id ));   
        shopService._delete( user, id )
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: SHOP_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: SHOP_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: SHOP_DELETE_FAILURE, error } }
}