import React, {Component} from 'react';
import Select from 'react-select';
import Button from '@material-ui/core/Button';

import ShopDialogAddContainer from '../../../../components/shop/shopDialogAddContainer/ShopDialogAddContainer';
import ShopDialogEditContainer from '../../../../components/shop/shopDialogEditContainer/ShopDialogEditContainer';


//import 'react-select/dist/react-select.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './InvoiceEdit.css'


class InvoiceForm extends Component {	
	

	render(){	
		const {invoice, shopList, 
			handleChange, handleSelectChange,handleInputChange, handleChangeSave, 
			handleBlur, 
			handleClickOpenAdd, handleCloseAdd, openAddDialog,
			handleClickOpenEdit, handleCloseEdit, openEditDialog} = this.props
		if( !invoice || !shopList) return false
			//to={"/shop/"+ invoice.shop_id} 
		const editShopLink = (invoice.shop_id)? <Button onClick={handleClickOpenEdit}>
			<FontAwesomeIcon icon="edit" /></Button>:""
		const addShopLink = <Button color="primary"  onClick={handleClickOpenAdd}>
			<FontAwesomeIcon icon="plus" /></Button>
		return <form className="card-body" >
				<div className="form-group">
				    <label>Shop</label>
				    <div className="row">
				    	<div className="col-8">
				    	<Select
							name="shop_id"
							defaultInputValue={(invoice.shop_name)?invoice.shop_name:""}
							options={Object.keys(shopList).map( (i) => {
								return (
									{ value: shopList[i].id, label: shopList[i].name }
								);
							})}
							onInputChange={handleInputChange}
							onChange={handleSelectChange}
						/>
						</div>
						<div className="col-4 shop-edit-link">
				  			{addShopLink}
				  			{editShopLink}
				  		</div>
				  	</div>
				  </div>
			  	<div className="row">
					<div className="form-group col-sm-6">
						<label>Date</label>
						<input type="date" className="form-control" 
							name="date" value={invoice.date}  
							onChange={handleChange}
							onBlur={handleBlur} />
					</div>
					<div className="form-group col-sm-6">
						<label>Time</label>
						<input type="time" className="form-control" 
							name="time" value={invoice.time}  
							onChange={handleChange}
							onBlur={handleBlur} />
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-12">
				    	<label>Total</label>
				    	<div className="input-group mb-3">
				    		<input type="text" className="form-control" placeholder="Total"
				    			name="total" value={invoice.total} 
				    			onChange={handleChange} 
				    			onBlur={handleBlur} />
				    		<div className="input-group-append">
    							<span className="input-group-text">$</span>
  							</div>
  						</div>
				  	</div>
				</div>
				<div className="row">
					<div className="form-group col-6">
				    	<label>Paid</label>
				    	<input type="checkbox" className="form-control" 
				    		name="paid" checked={(invoice.paid === "1")?true:false} 
				    		onChange={handleChangeSave} />
				  	</div>
					<div className="form-group col-6">
				    	<label>Days to Compensate</label>
				    	<input type="text" className="form-control" placeholder="Total"
				    		name="days_compensate" value={invoice.days_compensate} 
				    		onChange={handleChange} 
				    		onBlur={handleBlur} />
				  	</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-12">
				    	<textarea type="text" className="form-control" placeholder="Notes"
				    		name="notes" value={invoice.notes} 
				    		onChange={handleChange} 
				    		onBlur={handleBlur} />				    		
				  	</div>
				</div>
				<ShopDialogAddContainer 
					open={openAddDialog} handleClose={handleCloseAdd} />
				<ShopDialogEditContainer 					
					open={openEditDialog} 
					handleClose={handleCloseEdit} 
					shopId={invoice.shop_id} />
			</form>
	}
}

export default InvoiceForm;