import React, {Component} from 'react';

import InvoiceEditContainer from '../../../../components/invoice/invoiceEditContainer/InvoiceEditContainer';

import ProductAction from '../../../../containers/ProductAction';
import BillDeleteContainer from '../../../../components/billContainer/BillDeleteContainer';

class Invoice extends Component {	
	render() {
		if( !this.props.productList) return false
		if( !this.props.invoice) return false
			
		const invoice = this.props.invoice;
		return (
			<div>														
				<div className="card" style={{marginTop:'24px'}}>
					<div className="card-header">
						{invoice.shop_name} 
						<small style={{marginLeft: "12px"}}>{invoice.date_dma}</small>
						<div className="float-right">							
							<BillDeleteContainer id={invoice.bill_id} />
						</div>
					</div>
					<div className="card-body">    
						<InvoiceEditContainer invoice={invoice} />
					</div>
					<div className="card-footer">
						<ProductAction 
							invoice={invoice}
							productList={this.props.productList} />
					</div>
				</div>
			</div>
		);
	}
}

export default Invoice;