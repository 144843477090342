import {productService} from '../services';
import { alertActions } from './';

export const REQUEST_PRODUCT_BILL_LOAD = 'REQUEST_PRODUCT_BILL_LOAD'
function requestProductBillLoad( ) {
  return {
    type: REQUEST_PRODUCT_BILL_LOAD,
  }
}

export const RECEIVE_PRODUCT_BILL_LOAD = 'RECEIVE_PRODUCT_BILL_LOAD'
function receiveProductBillLoad( json ) {
  return {
    type: RECEIVE_PRODUCT_BILL_LOAD,
    list: json.elem,
  }
}

export function fetchProductBillLoad(user, billId) {
 
  return function (dispatch) {
    dispatch(requestProductBillLoad())

    return productService.billLoad( user, billId )
      .then(
        list => { 
          dispatch( receiveProductBillLoad(list) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );    
  }
}

export const REQUEST_PRODUCT_BILL_SAVE_FIELD = 'REQUEST_PRODUCT_BILL_SAVE_FIELD' 
function requestProductBillSaveField( ) {
  return {
    type: REQUEST_PRODUCT_BILL_SAVE_FIELD,
  }
}

export const RECEIVE_PRODUCT_BILL_SAVE_FIELD = 'RECEIVE_PRODUCT_BILL_SAVE_FIELD'
function receiveProductBillSaveField(productBill) {
  return {
    type: RECEIVE_PRODUCT_BILL_SAVE_FIELD,
    productBill: productBill,
  }
}

export function fetchProductBillSaveField( user, id, name, value ) {
  return function (dispatch) {
    dispatch(requestProductBillSaveField())

    return productService.productBillSaveField( user, id, name, value )
      .then(
        elem => { 
          dispatch( receiveProductBillSaveField(elem) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_PRODUCT_BILL_DELETE = 'REQUEST_PRODUCT_BILL_DELETE'
function requestProductBillDelete() {
  return {
    type: REQUEST_PRODUCT_BILL_DELETE,    
  }
}

export const RECEIVE_PRODUCT_BILL_DELETE = 'RECEIVE_PRODUCT_BILL_DELETE'
function receiveProductBillDelete( removedId, billId ) {
  return {
    type: RECEIVE_PRODUCT_BILL_DELETE,
    id: removedId,
    billId: billId
  }
}

export function fetchProductBillDelete( user, id ) {
 
  return function (dispatch) {
    dispatch(requestProductBillDelete())

    return productService.productBillDelete( user, id )
      .then(
        elem => { 
          dispatch( receiveProductBillDelete(elem.id, elem.bill_id) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}