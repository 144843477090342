import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';


import ChartSunBurst from '../statsContainer/stats/ChartSunBurst';

import {shopTypeList} from '../../constants/constants';
var moment = require("moment");


class StatsContainer extends Component { 
	constructor (props) { 		
		super(props);
				
		var momentFrom = moment("2018-01-23")
		var momentTo =	moment()
		let showShopType = []
		shopTypeList.map( (st) => {
			showShopType[st.key] =	{
				key: st.key,
				label: st.label,
				checked: (st.key === "house_expense" || st.key === "travel")?0:1,
			}
			return true
		})
		this.state = {
			filter: {
				from:	momentFrom.format("YYYY-MM-DD"),
				to:	momentTo.format("YYYY-MM-DD"),
				showShopType:	showShopType,
			},
		}

		this.handleChange = this.handleChange.bind(this);		
		this.handleShopTypeChange = this.handleShopTypeChange.bind(this);		

	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		let newState = "";
		if( name === "to" || name === "from "){
			newState = update( this.state, {
				filter: { [name]: {$set: moment(value).format("YYYY-MM-DD")} },
			});	
		}else{
			newState = update( this.state, {
				filter: { [name]: {$set: value} },
			});
		}

		this.setState( newState );
	}


	handleShopTypeChange(event) {
		const target = event.target;
	    
	    const value = target.checked;
		const name = target.name;		
		let newState = update( this.state, {
			filter: { showShopType: {
				[name]: {
					checked: {$set: value} },
				},
			},
		});			

		this.setState( newState );
	}

	render(){
		const {billList,shopList,countryList} = this.props
	   	if( !billList 
	   		|| !shopList
	   		|| !countryList ) return false

    		
		const main = <div className="row" style={{margin: '24px'}}>
				<div className="card col-12" >
					<ChartSunBurst 
						billList={this.props.billList}
						shopList={this.props.shopList}
						countryList={this.props.countryList}
						filter={this.state.filter} />
				</div>
			</div>

		return <div>{main}	</div>
	}
}
	
					
const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
    	billList: state.bill.list,
    	shopList: state.shop.list,		
    	countryList: state.country.list,		
	};
}
 

export default connect(mapStateToProps)(StatsContainer);  