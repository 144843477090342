import { connect } from 'react-redux'

import ProductAddForm from '../../components/product/ProductAddForm'

import { fetchProductAdd } from '../../actions/product'

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onAdd: (user, product) => dispatch( fetchProductAdd(user, product) ),
	}
}
 
const ProductAdd = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAddForm)


export default ProductAdd;