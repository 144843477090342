import React, {Component} from 'react';

class StatsMenu extends Component {	

	render(){
		const {handleChange,handleShopTypeChange,filter} = this.props
		return <ul className="nav flex-column" style={{padding: '20px'}}>
				<li>
					<div className="form-group">
						<label>From</label>
						<input type="date" name="from" className="form-control"
							onChange={handleChange}
							value={filter.from} />
						</div>
					</li>
					<li>
						<div className="form-group">
						<label>Until</label>
						<input type="date" name="to" className="form-control" 
							onChange={handleChange}
							value={filter.to} />
						</div>
				</li>
				<li>
					<div className="form-group">
						<label>Show:</label>
						<ul className="row">
						{Object.keys(filter.showShopType).map( (i) =>{	    							
							let shopType = filter.showShopType[i]
							return(
								<li className="col-6 form-check" key={shopType.key}>
									<input
										className="form-check-input"
    									name={shopType.key}
    									type="checkbox"
    									checked={shopType.checked}
    									onChange={handleShopTypeChange} />
    								<label className="form-check-label">{shopType.label}</label>
    							</li>
							)	
						})}
						</ul>
						
						</div>
					</li>
			</ul>
	}
}
export default StatsMenu;