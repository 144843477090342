import {
  REQUEST_COUNTRY_LIST,
  RECEIVE_COUNTRY_LIST,  
} from '../actions/country'
import update from 'immutability-helper';

const country = (state = [], action) => {
  let newState = "";

  switch (action.type) {
    case REQUEST_COUNTRY_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_COUNTRY_LIST:
      newState = update( state, {
        isFetching: {$set: false },
        list: {$set: action.list }
      });
      return newState
    default:
      return state
  }
}

export default country