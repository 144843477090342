import {
  REQUEST_PRODUCT_GET,
  RECEIVE_PRODUCT_GET,
  REQUEST_PRODUCT_DELETE,
  RECEIVE_PRODUCT_DELETE,
  REQUEST_PRODUCT_SAVE,
  RECEIVE_PRODUCT_SAVE,
  REQUEST_PRODUCT_SAVE_FIELD,
  RECEIVE_PRODUCT_SAVE_FIELD,
  REQUEST_PRODUCT_LIST,
  RECEIVE_PRODUCT_LIST,
  REQUEST_PRODUCT_BILL_LOAD,
  RECEIVE_PRODUCT_BILL_LOAD,
  REQUEST_PRODUCT_ADD,
  RECEIVE_PRODUCT_ADD,
  REQUEST_PRODUCT_BILL_GET_BY_PRODUCT_BILL,
  RECEIVE_PRODUCT_BILL_GET_BY_PRODUCT_BILL,
} from '../actions/product'

import {
  REQUEST_PATTERN_PRODUCT_ADD,
  RECEIVE_PATTERN_PRODUCT_ADD,   
  REQUEST_PATTERN_PRODUCT_SAVE_FIELD,
  RECEIVE_PATTERN_PRODUCT_SAVE_FIELD, 
  REQUEST_PATTERN_PRODUCT_DELETE,
  RECEIVE_PATTERN_PRODUCT_DELETE,
} from '../actions/pattern'

import update from 'immutability-helper';

const product = (state = [], action) => {
  let newState = "";
  switch (action.type) {
    case REQUEST_PRODUCT_DELETE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_DELETE:    
      newState = update( state, {
          isFetching: {$set: false},
          list: {$unset: [action.id]}
        });
      return newState        
      
    case REQUEST_PRODUCT_SAVE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_SAVE:
      newState = update( state, {
          list:{
            [action.bill.bill_id]: {$set: action.bill}
            }
        });
      return newState
    case REQUEST_PRODUCT_SAVE_FIELD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_SAVE_FIELD:
      newState = update( state, {
          list:{
            [action.product.id]: {$set: action.product}
            }
        });
      return newState  

    case REQUEST_PRODUCT_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.list,
      })    
    case REQUEST_PRODUCT_ADD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_ADD:
      newState = update( state, {
        isFetching: {$set: false},
        list: {
          [action.product.id]: {$set: action.product },
        },
      });
      return newState
    case REQUEST_PRODUCT_BILL_LOAD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_BILL_LOAD:
      return Object.assign({}, state, {
        isFetching: false,
        billList: action.list,
      }) 
    case REQUEST_PRODUCT_GET:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PRODUCT_GET:    
      if( state.product){
        newState = update( state, {
          isFetching: {$set: false},
          product:{ [action.product.id]: {$set: action.product} },
        })
      } else{
        newState = update( state, {
          isFetching: {$set: false},
          product: {$set: { [action.product.id]: action.product } },
        });
      }
      return newState
    case REQUEST_PRODUCT_BILL_GET_BY_PRODUCT_BILL:
      let productBillList = (state.productBillList)?state.productBillList:{}
      return Object.assign({}, state, {
        isFetching: true,
        productBillList: productBillList,
      })
      
    case RECEIVE_PRODUCT_BILL_GET_BY_PRODUCT_BILL:
      newState = update( state, {
        isFetching: {$set: false},
        productBillList: {
          [action.product.id]: {$set: action.product },
        },
      });
      return newState

    case REQUEST_PATTERN_PRODUCT_ADD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PATTERN_PRODUCT_ADD:    
      newState = update( state, {
          isFetching: {$set: false},
          product: {
            [action.pattern.product_id] :{
              pattern_list:{
                [action.pattern.id]: {$set: action.pattern},
              }
            }
          }
        });
      return newState  
    case REQUEST_PATTERN_PRODUCT_SAVE_FIELD:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PATTERN_PRODUCT_SAVE_FIELD:    
      newState = update( state, {
          isFetching: {$set: false},
          product: {
            [action.pattern.product_id]: {
              pattern_list:{
                [action.pattern.id]: {$set: action.pattern},
              }
            },
          },          
        });
      return newState    
    
    case REQUEST_PATTERN_PRODUCT_DELETE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_PATTERN_PRODUCT_DELETE:    
      newState = update( state, {
          isFetching: {$set: false},
          product: {
            [action.pattern.product_id] :{
              pattern_list: {$unset: [action.pattern.id]}
            }
          },
        });
      return newState  
    default:
      return state
  }
}

export default product