import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import MenuContainer from './base/menuContainer/MenuContainer';

const styles = theme => ({
	container: {
		display: 'flex',
		height: '100vh',
		overflow: 'hidden',
		marginTop: theme.spacing(7),
	},
	side: {
  		overflow: 'auto',
  		overflowX: 'hidden',
  		height: '100vh',
  		backgroundColor: theme.palette.background.white,
  		boxShadow:  theme.shadows[3],  		
  	},
  	main: {
  		height: '100vh',
  		overflow: 'auto',
  		backgroundColor: theme.palette.primary.light,
  	}
});

const Page2Col = (props) => {
	const {classes} = props
	return <div>
		<MenuContainer 
			year={props.year}
			handleYearChange={props.handleYearChange} />
		<Grid container className={classes.container} justify="center">	
			<Grid item xs={12} sm={3} className={classes.side}>
				<div className={classes.colContainer}>
					{props.side}
				</div>
			</Grid>
			<Grid item xs={12} sm={9} className={classes.main}>
				<div className={classes.colContainer}>
					{props.main}			
				</div>
			</Grid>
		</Grid>
	</div>    
}

export default withStyles(styles)(Page2Col)