import React from 'react';

import ShopDialogAddContainer from '../../../shop/shopDialogAddContainer/ShopDialogAddContainer';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { withStyles } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider';

import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import {  NavLink } from 'react-router-dom';

import {getConstant} from '../../../../functions/functions';
import {shopTypeList} from '../../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const styles = theme => ({
	badge:{
		textAlign: "right",
	},
	country: {
		textTransform: "uppercase",
		fontWeight: "bold",
	},
	city:{
		color: "green",
		fontWeight: "bold",
		backgroundColor: "white",
	},
})

let printShopNav = ( shop, classes ) => {
	var icon = "";
	if( shop.type ){
		const shopType = getConstant( shopTypeList, shop.type );
		icon = <FontAwesomeIcon icon={shopType.icon} style={{'color': 'green'}}/>;
	}
	let badge = (parseInt(shop.invoice_num,10) > 1)?<span className="badge badge-primary">{shop.invoice_num}</span>:"";
	
	return <ListItem key={shop.id} component={NavLink} to={`/shop/${shop.id}`} >
		<ListItemIcon>
			{icon}
		</ListItemIcon>	
		<ListItemText primary={(shop.name)?shop.name:"..."} />
		<ListItemText className={classes.badge} primary={badge} />
	</ListItem>
}

const ShopMenu = ({ date, list, total, cityList, cityCountry, classes,handleCloseAdd, handleClickOpenAdd, openAddDialog }) => {

	let shopMenuCountry = Object.keys(cityCountry).map( c => {
		let shopCountry = cityCountry[c]		
		
		let cityMenu = Object.keys(shopCountry.list.city).map( i => {
			let shopCity = shopCountry.list.city[i]
			let cityName = <ListSubheader className={classes.city} disableSticky={false}>{shopCity.city.name}</ListSubheader>
			return <div key={"cityMenu_"+ shopCity.city.id}>
				{cityName}
				{Object.keys(shopCity.list).map( i => {
					let shop = shopCity.list[i]
					return printShopNav(shop, classes)
					})}
		    </div>

		})
		
		let generalMenu = ""
		if( shopCountry.list.general ){
			generalMenu = <div key={"subheader_"+ shopCountry.country.id} >
				<ListSubheader className={classes.city} disableSticky={false} >General of {shopCountry.country.name}</ListSubheader>
				{shopCountry.list.general.map( shop => printShopNav(shop, classes) )}
			</div>

		}

		return <List key={date}>			
			<ListSubheader className={classes.country} disableSticky={true}>
				<Button color="secondary" onClick={handleClickOpenAdd} style={{cursor: "pointer"}}>
					<FontAwesomeIcon icon="plus"  style={{marginRight: '8px'}} /> 
					{shopCountry.country.name}</Button> 
				
			</ListSubheader>
			{cityMenu}
			{generalMenu}
			<Divider />
			<ShopDialogAddContainer 
				open={openAddDialog} 
				handleClose={handleCloseAdd} />
		</List>
	})

	return shopMenuCountry
}

export default withStyles(styles)(ShopMenu)