import { connect } from 'react-redux'

import PatternForm from '../../components/pattern/PatternForm'

import { fetchPatternShopSaveField, fetchPatternShopAdd, fetchPatternShopDelete } from '../../actions/pattern'


const mapStateToProps = (state, ownProps) => {
	return {
		...state,
		...ownProps,
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onAdd: (user, relId, pattern) => dispatch( fetchPatternShopAdd(user, relId, pattern) ),		
		onSaveField: (user, id, name, value) => dispatch( fetchPatternShopSaveField(user, id, name, value) ),
		onDelete: (user,id) => dispatch( fetchPatternShopDelete(user, id) ),
	}
}
 
const PatternShopContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatternForm)


export default PatternShopContainer;