import {billService} from '../services';
import { alertActions } from './';

export const billActions = {  
  add,
  _delete,
}


export const REQUEST_BILL_LIST = 'REQUEST_BILL_LIST'
function requestBillList() {
  return {
    type: REQUEST_BILL_LIST,    
  }
}

export const RECEIVE_BILL_LIST = 'RECEIVE_BILL_LIST'
function receiveBillList( list ) {
  return {
    type: RECEIVE_BILL_LIST,
    list: list,
  }
}

export function fetchBillList( user ) {
 
  return function (dispatch) {
    dispatch(requestBillList())

    return billService.loadList( user )
      .then(
        list => { 
          dispatch( receiveBillList(list) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_BILL_LIST_BY_YEAR = 'REQUEST_BILL_LIST_BY_YEAR'
function requestBillListByYear( year ) {
  return {
    type: REQUEST_BILL_LIST_BY_YEAR,    
  }
}

export function fetchBillListByYear( user, year ) {
 
  return function (dispatch) {
    dispatch(requestBillListByYear( user, year))

    return billService.loadListByYear( user, year )
      .then(
        list => { 
          dispatch( receiveBillList(list) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_BILL_INSPECT = 'REQUEST_BILL_INSPECT'
function requestBillInspect( ) {
  return {
    type: REQUEST_BILL_INSPECT,
  }
}

export const RECEIVE_BILL_INSPECT = 'RECEIVE_BILL_INSPECT'
function receiveBillInspect(elem) {
  	return {
   	type: RECEIVE_BILL_INSPECT,
   	invoice: elem,
  	}
}

export function fetchBillInspect( user, id ) {
  	return function (dispatch) {
   	dispatch(requestBillInspect(id))

  	return billService.inspect( user, id )
      .then(
        	elem => { 
         	dispatch( receiveBillInspect(elem) );
        	},
        	error => {
         	dispatch( alertActions.error(error) );
        	}
      );
  	}
}

export const REQUEST_BILL_DIGEST = 'REQUEST_BILL_DIGEST'
 
function requestBillDigest( ) {
  return {
    type: REQUEST_BILL_DIGEST
  }
}

export const RECEIVE_BILL_DIGEST = 'RECEIVE_BILL_DIGEST'
 
function receiveBillDigest(invoice) {
  return {
    type: RECEIVE_BILL_DIGEST,
    invoice: invoice,
  }
}

export function fetchBillDigest( user, id ) {
  return function (dispatch) {
    dispatch(requestBillDigest())

    return billService.digestRegion( user, id )
      .then(
        invoice => { 
          dispatch( receiveBillDigest(invoice) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const REQUEST_BILL_READ_REGIONS = 'REQUEST_BILL_READ_REGIONS'
 
function requestBillReadRegions( ) {
  return {
    type: REQUEST_BILL_READ_REGIONS
  }
}

export const RECEIVE_BILL_READ_REGIONS = 'RECEIVE_BILL_READ_REGIONS'
 
function receiveBillReadRegions(invoice) {
  return {
    type: RECEIVE_BILL_READ_REGIONS,
    invoice: invoice,
  }
}

export function fetchBillReadRegions( user, billId, regions ) {
  return function (dispatch) {
    dispatch(requestBillReadRegions())

    return billService.readRegions( user, billId, regions )
      .then(
        invoice => { 
          dispatch( receiveBillReadRegions(invoice) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );
  }
}

export const BILL_ADD_REQUEST = 'BILL_ADD_REQUEST'
export const BILL_ADD_SUCCESS = 'BILL_ADD_SUCCESS'
export const BILL_ADD_FAILURE = 'BILL_ADD_FAILURE'

function add( user, bill ) {    
    return dispatch => {
        dispatch(request( bill ));   
        billService.add( user, bill )
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( bill ) { 
        return { type: BILL_ADD_REQUEST, bill } 
    }
    function success(rows) { return { type: BILL_ADD_SUCCESS, rows } }
    function failure(error) { return { type: BILL_ADD_FAILURE, error } }
}

export const BILL_DELETE_REQUEST = 'BILL_DELETE_REQUEST'
export const BILL_DELETE_SUCCESS = 'BILL_DELETE_SUCCESS'
export const BILL_DELETE_FAILURE = 'BILL_DELETE_FAILURE'

function _delete( user, id ) { 
    return dispatch => {
        dispatch(request( id ));   
        billService._delete( user, id )
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: BILL_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: BILL_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: BILL_DELETE_FAILURE, error } }
}
/*

export const REQUEST_BILL_DELETE = 'REQUEST_BILL_DELETE'
function requestBillDelete() {
  return {
    type: REQUEST_BILL_DELETE,    
  }
}

export const RECEIVE_BILL_DELETE = 'RECEIVE_BILL_DELETE'
function receiveBillDelete( deletedId ) {
  return {
    type: RECEIVE_BILL_DELETE,
    id: deletedId
  }
}

export function fetchBillDelete( user, id ) {
  return function (dispatch) {
    dispatch(requestBillDelete())

    return billService._delete( user, id )
      .then(
        id => { 
          dispatch( receiveBillDelete(id) );
        },
        error => {
          dispatch( alertActions.error(error) );
        }
      );    
  }
}*/