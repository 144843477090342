import React, {Component} from 'react';

import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';


//import 'react-select/dist/react-select.css';

class ProductBillEditForm extends Component {
	constructor (props) { 
		super(props);
				
		this.state = {
			product: {},		
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleFocus = this.handleFocus.bind(this);

	}

	componentWillMount( ){
		this.setState({
			product: this.props.product
		});
	}

	componentWillReceiveProps( newProps ){
		this.setState({
			product: newProps.product
		});
	}

	handleFocus(event) {
  		event.target.select();
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			product: { [name]: {$set: value} },
		});

		this.setState( newState );
	}
	handleSelectChange = (selectedOption) => {	
		if( selectedOption ){
			let newState = update( this.state, {
				product: { product_id: {$set: selectedOption.value} },
			});
			this.setState( newState );
			this.props.onSaveField( this.props.authentication.user, this.props.product.product_bill_id, "product_id", selectedOption.value )			
		}
  	}

	handleBlur( event ){
  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		this.props.onSaveField( this.props.authentication.user, this.props.product.product_bill_id, name, value )

		const product = this.state.product
		if( name === "price_unit" || name === "num" ){
			const priceNew = parseFloat(product.price_unit * product.num).toFixed(2);
			let newState = update( this.state, {
				product: { price: {$set: priceNew } },
			});
			this.setState( newState );
			this.props.onSaveField( this.props.authentication.user, this.props.product.product_bill_id, "price", priceNew )
		}

  	}	

	render(){
		if( !this.props.productList) return false
			
		const product =	this.state.product;		
		const productList =	this.props.productList;				
		const productOptions = Object.keys(productList).map( (i) => {
			return (
				{ value: productList[i].id, label: productList[i].name }
			);
		});

		return ( 
		<div>
			<div className="row" style={{marginBottom: '4px'}}>
				<div className="col-9">
					<Select
						name="product_id"
						value={product.product_id}
						options={productOptions}
						onChange={this.handleSelectChange.bind( this )}
					/>				
				</div>
				<div className="col-3">
					<div className="btn-group" style={{width: "100%"}}>
						{(this.props.onModalProduct)?(
							<button className="btn btn-primary"
								onClick={() => this.props.onModalProduct( product.product_id )}>
								<FontAwesomeIcon icon="pencil-alt" />
							</button>
							):""
						}
						{(this.props.onModalPattern)?(
							<button className="btn btn-success"
								onClick={() => this.props.onModalPattern( product.product_det_id )}>					
								<FontAwesomeIcon icon="paw" />
							</button>):""
						}
						{(this.props.onDelete)?(
							<button className="btn btn-danger"
								onClick={() => { if (window.confirm('Are you sure you wish to delete this product?')) this.props.onDelete( this.props.authentication.user, this.props.product.product_bill_id ) } }>
								<FontAwesomeIcon icon="trash-alt" />
							</button>):""
						}
					</div>
				</div>
			</div>
			<div className="row" style={{marginBottom: '12px'}}>
				<div className="col-4">
					<div className="input-group mb-3">
  						<input type="text" className="form-control" placeholder="Price Unit"
							name="price_unit" 
							value={product.price_unit}
							onFocus={this.handleFocus}
							onChange={this.handleChange}
							onBlur={this.handleBlur} />
  						
  						<div className="input-group-append">
    						<span className="input-group-text">$</span>
  						</div>
					</div>
				</div>
				<div className="col-4" >
					<div className="row">
						<div className="col-1">
							<span className="align-middle font-weight-bold" style={{fontSize: "20px"}}>x</span>
						</div>
						<div className="col-8">
							<input type="text" className="form-control" placeholder="Num"
							style={{width: "100%"}}
								name="num" 
								value={product.num} 
								onFocus={this.handleFocus}
								onChange={this.handleChange}
								onBlur={this.handleBlur} />
						</div>
						<div className="col-1">
							<span className="align-middle font-weight-bold" style={{fontSize: "20px"}}>=</span>
						</div>
					</div>
				</div>

				<div className="col-4">
					<div className="input-group mb-3" >
  						<input type="text" className="form-control" placeholder="Price"
							name="price" 
							value={product.price} 
							onFocus={this.handleFocus}
							onChange={this.handleChange}
							onBlur={this.handleBlur} />
  						
  						<div className="input-group-append">
    						<span className="input-group-text">$</span>
  						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}

export default ProductBillEditForm;