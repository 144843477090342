import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {  NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';

import ListIcon from '@material-ui/icons/List';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import CloseIcon from '@material-ui/icons/Close';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  	formControl: {
    	margin: 12,
    	minWidth: 120,
    },
};


let elementsMenu = [
	{
		name: "Invoices",
		url:  "/",
		icon:   <ListIcon />
	},
	{
		name: "Shops",
		url:  "/shop",
		icon:   <LocalMallIcon />
	},
	{
		name: "Products",
		url:  "/product",
		icon:   <LocalOfferIcon />
	},
	{
		name: "Stats",
		url:  "/stats",
		icon:   <InsertChartIcon />
	},
	{
		name: "Logout",
		url:  "/logout",
		icon:   <CloseIcon />,
		hideName: true
	}
]

var moment = require("moment");

class Menu extends Component { 
  	constructor(props) {
	   	super(props);

	   	this.state = {
	      	drawerOpen: false
	   	}
  	}

  	toggleDrawer = (open) => () => {
   		this.setState({ drawerOpen: open });
  	};

  	render(){
		const {classes,year,handleYearChange} = this.props
		
		const year_ini = 2018;		
		const years = []
		for( let i = year_ini; i<=moment().format("YYYY"); i++){
			years.push(i)
		}

		return <AppBar position="fixed">
			<Toolbar variant="dense">
				<IconButton className={classes.menuButton} 
	          		color="inherit" aria-label="Menu"
	          		onClick={this.toggleDrawer( true ) }>
	                <MenuIcon />
	            </IconButton>

	        	<Typography variant="h6" color="inherit" className={classes.flex} component={NavLink} to="/" >
	           		Budget
	        	</Typography>     
				<FormControl className={classes.margin} variant="filled">
        			<Select
        				id="select-year"
        				className={classes.formControl}
				        value={year}
				        onChange={handleYearChange}>
				          <MenuItem value="">
				            <em>All</em>
				          </MenuItem>
				          {years.map( y =><MenuItem value={y}>{y}</MenuItem> )}				          
        			</Select>
      			</FormControl>

	        	<Hidden smDown>
				{
					elementsMenu.map( (m) => {		
						if( m.hideName ){
							return <IconButton key={m.url} aria-label="Logout" className={classes.logout}
								component={NavLink} to={m.url} >
								<CloseIcon />
							</IconButton>
						}else{
							return <Button 
								key={m.url} 
								color="inherit" 
								component={NavLink} to={m.url}>{m.name}</Button>							
						}
					})
				}
				</Hidden>
			</Toolbar>	
			<Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
	        	<div className={classes.list}
	          		tabIndex={0}
	          		role="button"
	          		onClick={this.toggleDrawer( false) }
	          		onKeyDown={this.toggleDrawer( false) }>
	          		<List>
						{elementsMenu.map( (m) => <ListItem key={m.url} button component="a" 
							href={m.url}>
							<ListItemIcon>{m.icon}</ListItemIcon>
							<ListItemText primary={m.name} />							
						</ListItem>
						)}
						<Divider />
					</List>				
	        	</div>
	      	</Drawer>          	      
		</AppBar>
	}
}

export default withStyles(styles)(Menu);