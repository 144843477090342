import {
  CITY_LOAD_REQUEST,
  CITY_LOAD_SUCCESS,
  CITY_LOAD_FAILURE,
  CITY_ADD_REQUEST,
  CITY_ADD_SUCCESS,
  CITY_ADD_FAILURE,  
  CITY_DELETE_REQUEST,
  CITY_DELETE_SUCCESS,
  CITY_DELETE_FAILURE,  


} from '../actions/city'



import update from 'immutability-helper';

const city = (state = [], action) => {
  let newState = "";

  switch (action.type) {
    case CITY_LOAD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case CITY_LOAD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.rows,
      })
    case CITY_LOAD_FAILURE:
      return {}  

    case CITY_ADD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case CITY_ADD_SUCCESS:    
      newState = update( state, {
          isFetching: {$set: false},
          list: {
            [action.rows.id]: {$set: action.rows},
          }
        });
      return newState
    case CITY_ADD_FAILURE:
      return {} 

    
    case CITY_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case CITY_DELETE_SUCCESS:    
      newState = update( state, {
          isFetching: {$set: false},
          list: {$unset: [action.rows]},
          city: {$set: {}},
        });
      return newState
    case CITY_DELETE_FAILURE:
      return {} 
    
    default:
      return state
  }
}

export default city