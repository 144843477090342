import React, {Component} from 'react';
import { connect } from 'react-redux'

import {DOMAIN_URL} from '../../constants';

import { fetchBillDigest } from '../../actions'


class BillRegion extends Component {

	render() {
		const region_src = DOMAIN_URL + this.props.bill.file_path + this.props.region.src;
		return (
			<div className="media">
  				<img className="mr-3" src={region_src} alt={this.props.region.type} 
  					style={{maxWidth: '200px'}}/>
  				<div className="media-body">
    				<h5 className="mt-0">{this.props.region.type}</h5>
    				<button className="btn btnPrimary"
    					onClick={ () => this.props.onDigest( this.props.authentication.user, this.props.region.id )}
    					>Read</button>
  				</div>
			</div>
		);
	}
}

const mapStateToProps = (state,ownProps)  => {  
  	return {
      authentication: state.authentication, 
  	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		onDigest: (user, id) => dispatch( fetchBillDigest(user, id)  )
	}
}

BillRegion = connect(mapStateToProps,mapDispatchToProps)(BillRegion)

export default BillRegion;