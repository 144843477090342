import React, { Component } from 'react'

import { line,curveCatmullRom } from 'd3-shape'

var moment = require("moment");

//import './Bars.css'

export default class Lines extends Component {


  	render() {
   	const { scales, data } = this.props

		var newLine = line()
    		.x(function(d) { 
    			return scales.xScale(moment(d.date)) })
    		.y(function(d) { return scales.yScale(d.money) })
    		.curve(curveCatmullRom.alpha(0.5));
    	
    	const lines = ( 
      	data.map( datum => {	return <path
              	key={datum.date}
              	fill="none"
              	stroke="steelblue"
      			strokeLinejoin = "round"
      			strokeLinecap= "round"
      			strokeWidth = "2"
      			d={newLine(data)}
            />
        	})        
		)

    	return (
      	<g>{lines}</g>
    	)
  	}
}