import { combineReducers } from 'redux'
import bill from './bill'
import shop from './shop'
import product from './product'
import country from './country'
import city from './city'
import alert from './alert'
import authentication from './authentication'


const todoApp = combineReducers({
  alert,
  authentication,
  bill,
  country,
  city,
  shop,
  product,
})
 
export default todoApp