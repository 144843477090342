export function isEmpty(obj) {
    if (obj == null) return true;

    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function getConstant( constantList, key){
	for (let constant of constantList) {
		if( constant.key === key ){
			return constant;
		}
    }
    return {};
};