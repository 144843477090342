import React, {Component} from 'react';


import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ShopEditContainer from '../../../components/shop/shopEditContainer/ShopEditContainer';

import Slide from '@material-ui/core/Slide';

const styles = theme => ({
	billImageContainer: {
		height: "50vh",
		marginTop: "23px",
		overflow: "scroll",
	}
	
})

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ShopDialogEditContainer extends Component {	
	constructor (props) {
		super(props)
		
		this.state = {
			imageExpanded: false,
		}
	}	

	render() {		
		const {open,handleClose,shopId} = this.props;
		return <Dialog open={open}
					onClose={handleClose}
					maxWidth="md"
					fullWidth={true}
					TransitionComponent={Transition}>
		<DialogContent>
			<ShopEditContainer shopId={shopId} />
         </DialogContent>
		</Dialog>
	}
}

export default withStyles(styles)(ShopDialogEditContainer)