import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import ShopView from './shopView/ShopView';


import { fetchShopGet, fetchShopSaveField } from '../../../actions'

class ShopViewContainer extends Component { 
	
	constructor(props) {
    	super(props)
    	this.state = {
    		shopId: "",
    		zoomableSrc: "",
    	}
  	}

  	getShop( shopId ){
		let newState = update( this.state, {
			shopId: {$set: shopId},
		});
		this.setState( newState );	

		const dispatch = this.props.dispatch;
		dispatch( fetchShopGet( this.props.auth.user, shopId ))
  	}

	componentWillMount( ){
		if( !this.props.shopId ) return false
		this.getShop( this.props.shopId )		
	}

	componentWillReceiveProps( newProps ){
		if( !newProps.shopId ) return false
		
		const shopId =	newProps.shopId

		if( shopId !== this.props.shopId){
			this.getShop( shopId  )
		}
	}
	
	showInvoice = ( src ) => {
		this.setState({
			zoomableSrc: src,
		})
	} 
	

	render(){
		if( !this.props.shop ) return false

		return <ShopView shop={this.props.shop} shopId={this.props.shopId} 
			onShowInvoice={this.showInvoice} onSaveField={this.props.onSaveField}
			zoomableSrc={this.state.zoomableSrc} 
			countryList={this.props.countryList} cityList={this.props.cityList}
 		/>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
		shop: state.shop.shop,
		countryList: state.country.list,
		cityList: state.city.list,

	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
		onSaveField: (user, id, name, value) => dispatch( fetchShopSaveField(user,id,name,value) ),		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopViewContainer);  