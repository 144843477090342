import React, {Component} from 'react';

import { connect } from 'react-redux'

import Page2Col from '../Page2Col';

import ShopViewContainer from '../shop/shopViewContainer/ShopViewContainer';
import ShopMenuContainer from '../shop/shopMenuContainer/ShopMenuContainer';

import AlertContainer from '../../containers/AlertContainer';


class ShopPage extends Component {

    render(){
        const side = <ShopMenuContainer />

        const main = <div>
            <AlertContainer />
            <ShopViewContainer shopId={this.props.match.params.shopId} />
        </div>
        
        return <Page2Col side={side} main={main}  />
        
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.authentication,
        billList: state.bill.list,
        invoice: state.bill.invoice,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
