import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import InvoiceEdit from './invoiceEdit/InvoiceEdit'
import { fetchInvoiceSaveField } from '../../../actions'


class InvoiceEditContainer extends Component { 
	constructor (props) { 		
		super(props);
		
		this.state = {
			invoice: {},
			inputValue: "",
			openAddDialog : false,
			openEditDialog : false
		}
		

		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeSave = this.handleChangeSave.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}	
	componentWillMount( ){
		if( this.props.invoice ){	
			let newState = update( this.state, {
				invoice: {$set: this.props.invoice},
			});
			this.setState( newState );
		}
	}

	componentWillReceiveProps( nextProps ){
		if( nextProps.invoice ){	
			let newState = update( this.state, {
				invoice: {$set: nextProps.invoice},
			});
			this.setState( newState );
		}
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			invoice: { [name]: {$set: value} },
		});

		this.setState( newState );
	}
	handleChangeSave(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			invoice: { [name]: {$set: value} },
		});

		this.setState( newState );
		this.props.onSaveField( this.props.auth.user, this.state.invoice.bill_id, name, value )					
	}
	handleSelectChange = (selectedOption) => {	
		
		if( selectedOption ){
			let newState = update( this.state, {
				invoice: { shop_id: {$set: selectedOption.value} },
			});
			this.setState( newState );
			this.props.onSaveField( this.props.auth.user, this.state.invoice.bill_id, "shop_id", selectedOption.value )			
		}
  	}

	handleBlur( event ){
  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.props.onSaveField( this.props.auth.user, this.state.invoice.bill_id, name, value )
  	}

  	handleInputChange = (newValue: string) => {
    	const inputValue = newValue.replace(/\W/g, '');
    	this.setState({ inputValue });
    	return inputValue;
  	}

	handleClickOpenAdd = () => {
    	this.setState({ openAddDialog: true });
  	};

  	handleCloseAdd = () => {
    	this.setState({ openAddDialog: false });
  	};

  	handleClickOpenEdit = () => {
    	this.setState({ openEditDialog: true });
  	};

  	handleCloseEdit = () => {
    	this.setState({ openEditDialog: false });
  	};	

	render(){
	    return (
	    	<InvoiceEdit 
	    		invoice={this.state.invoice}
	    		shopList={this.props.shopList}
	    		handleChange={this.handleChange}
	    		handleSelectChange={this.handleSelectChange}
	    		handleChangeSave={this.handleChangeSave}
	    		handleBlur={this.handleBlur}
	    		handleClickOpenAdd={this.handleClickOpenAdd}
	    		handleCloseAdd={this.handleCloseAdd}
	    		handleClickOpenEdit={this.handleClickOpenEdit}
	    		handleCloseEdit={this.handleCloseEdit}
	    		openAddDialog={this.state.openAddDialog}
	    		openEditDialog={this.state.openEditDialog}
	    	/>      		
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.authentication,
    	shopList: state.shop.list,		
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,		
		onSaveField: (user, id, name, value) => dispatch( fetchInvoiceSaveField(user, id, name, value) ),
	}
}
 
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEditContainer);  